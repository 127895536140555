// TR
export const locale = {
    common: {
        edit: "Düzenle",
        yes: "Evet",
        no: "Hayır",
        new: "Yeni",
        back: "Geri",
        language: "Dil",
        currency: "Para Birimi",
        cancel: "Vazgeç",
        send: "Gönder",
        confirm: "Devam Et",
        date: "Tarih",
        save: "Kaydet",
        report: "Bildir",
        active: "Aktif",
        passive: "Pasif",
        remove: "Sil",
        action: "İşlem",
        search: "Ara",
        close: "Kapat",
        preview: "Önizleme",
        today: "Bugün",
        daily: "Günlük",
        weekly: "Haftalık",
        monthly: "Aylık",
        yearly: "Yıllık",
        month: "Ay",
        day: "Gün",
        year: "Yıl",
        week: "Hafta",
        viewAll: "Tümü",
        notification: "Bildirim",
        download: "İndir",
        optional: "Opsiyonel",
        permission: "Yetki",
        manager: "Yönetici",
        default: "Varsayılan",
        status: "Durum",
        sort: "Sıra",
        createdAt: "Oluşturulma Tarihi",
        chooseSelect: "Seçim yapın.",
        chooseOption: "Özellik seçimi yapın",
        canChooseOption: "Özellik seçimi yapabilirsiniz",
        chooseDate: "Tarih seçimi yapın.",
        chooseTime: "Saat seçimi yapın.",
        chooseDateAndTime: "Tarih ve saat seçimi yapın.",
        individual: "Bireysel",
        corporate: "Kurumsal",
        cash: "Peşin",
        system: "Sistem",
        export: "Dışa Aktar",
        overview: "Genel Bakış",
        settings: "Ayarlar",
        homePage: "Anasayfa",
        id: "ID",
        true: "Doğru",
        false: "Yanlış",
        hour: "Saat",
        minute: "Dakika",
        second: "Saniye",
        empty: "Boş",
        indefinite: "Süresiz",
        ipAddress: "İp Adresi",
        userAgent: "User Agent",
        immediately: "Hemen",
        exportTooltip: "Filtreleme yapılmadığında liste maksimum 1.000 kayıt olarak sınırlandırılacaktır.",
        translation: "Çeviri"
    },
    menu: {
        systemManagement: "Sistem Yönetimi",
        users: "Kullanıcılar",
        paymentMethods: "Ödeme Yöntemleri",
        shippingMethods: "Kargo Yöntemleri",
        orders: "Siparişler",
        orderChangeUsageMulti: "Kullanım Tarihi İşlemleri",
        admins: "Yöneticiler",
        dashboard: "Gösterge Paneli",
        ecommerceManagement: "E-Ticaret Yönetimi",
        productManagement: "Ürün Yönetimi",
        localizationManagement: "Yerelleştirme Yönetimi",
        accountManagement: "Hesap Yönetimi",
        mediaManagement: "Medya Yönetimi",
        moduleManagement: "Modül Yönetimi",
        invoiceManagement: "Fatura Yönetimi",
        orderManagement: "Sipariş Yönetimi",
        userManagement: "Kullanıcı Yönetimi",
        categories: "Kategoriler",
        products: "Ürünler",
        variants: "Varyantlar",
        invoices: "Faturalar",
        invoiceProviders: "Fatura Sağlayıcıları",
        userCreator: "Toplu Kullanıcı Tanımlama",
        orderCreator: "Toplu Sipariş Tanımlama",
        orderShipping: "Lojistik İşlemleri",
        modules: "Modüller",
        taxes: "Vergiler",
        media: "Medyalar",
        streamProvider: "Medya Sağlayıcıları",
        pages: "Sayfalar",
        variables: "Sistem Değişkenleri",
        systemSetting: "Sistem Ayarları",
        siteSetting: "Site Ayarları",
        linkManager: "Link Yönetimi",
        statistic: {
            title: "İstatistikler",
            general: "Genel",
            student: "Öğrenci",
            lesson: "Ders",
            product: {
                title: "Ürün (Genel)",
                specific: "Ürün (Belirli)"
            }
        },
        notification: {
            title: "Bildirim Yönetimi",
            item: {
                group: "Bildirim Öğe Grupları"
            }
        },
        sms: {
            title: "SMS Yönetimi",
            provider: "SMS Sağlayıcıları",
            multiSending: "Toplu SMS Gönderme"
        },
        discountCouponManagement: {
            title: "İndirim Yönetimi",
            coupons: "Kuponlar",
            creator: "Otomatik Tanımlama"
        },
    },
    messages: {
        warning: "Uyarı",
        success: "Başarılı",
        error: "Hata",
        sureAction: "İşlemi yapmak istediğinize emin misiniz?",
        sureConfirm: "Onaylamak istediğinize emin misiniz?",
        sureDelete: "Silme işlemi yapmak istediğinize emin misiniz?",
        sureCancel: "İptal işlemi yapmak istediğinize emin misiniz?",
        actionOk: "İşleminiz başarılı bir şekilde tamamlanmıştır.",
        deleteOk: "Silme işleminiz başarılı bir şekilde tamamlanmıştır.",
        cancelOk: "İptal işleminiz başarılı bir şekilde tamamlanmıştır.",
        copyOk: "Kopyalama başarıyla tamamlanmıştır.",
        selectRow: "Lütfen bir kayıt seçiniz!",
        wait: "Lütfen Bekleyiniz!",
        loading: "Yükleniyor",
        noResultFound: "Sonuç bulunamadı!"
    },
    btn: {
        new: "Yeni",
        add: "Ekle",
        create: "Oluştur",
        copy: "Kopyala",
        edit: "Düzenle",
        delete: "Sil",
        yes: "Evet",
        no: "Hayır",
        cancel: "Vazgeç",
        close: "Kapat",
        refresh: "Yenile",
        update: "Güncelle",
        filter: "Filtrele",
        save: "Kaydet",
        saveAll: "Tümünü Kaydet",
        select: "Seç",
        selectFile: "Dosya Seç",
        upload: "Yükle",
        download: "İndir",
        action: "İşlem",
        previous: "Önceki",
        ensuing: "Sonraki",
        next: "İleri",
        back: "Geri",
        send: "Gönder",
        ok: "Tamam",
        clear: "Temizle",
        tryAgain: "Tekrar Deneyin!",
        apply: "Uygula",
        reset: "Sıfırla",
        change: "Değiştir",
        check: "Denetle",
        selectedRowEvent: "Seçili Kayıt İşlemleri"
    },
    validation: {
        required: "Bu alan gereklidir.",
        email: "Bu alan geçerli bir e-posta olmalıdır.",
        number: "Bu alan geçerli bir sayı olmalıdır.",
        confirmed: "Bu alan onayı eşleşmiyor.",
        length: "Bu alanda 2 seçenek olmalıdır.",
        password: "Lütfen geçerli bir şifre girin.",
        passwordConfirmation: "Lütfen şifreyi tekrar girin.",
        dontMatchPassword: "Şifre alanları birbiriyle eşleşmiyor!"
    },
    pages: {
        login: {
            title: "Uzaktan Eğitim Yönetim Paneli",
            cols: {
                email: "E - Posta",
                password: "Şifre"
            },
            successLogin: "Başarıyla giriş yaptınız!",
            btnText: "Giriş Yap",
            responseMessages: {
                invalidCredentials: "E - Posta veya Şifre yanlış!",
                forbiddenRequest: "Giriş işlemi başarısız. Lütfen IP adresinizin izinli olduğundan emin olunuz!"
            }
        },
        system: {
            admin: {
                title: "Yöneticiler",
                subTitle: "Yönetici Listesi",
                groupSelect: "Lütfen grup seçiniz.",
                newAdmin: "Yeni Yönetici",
                editAdmin: "Yönetici Düzenle",
                adminGroups: "Yönetici Grupları",
                changePassword: "Şifre Değiştirme",
                cols: {
                    name: "Ad & Soyad",
                    email: "E - Posta",
                    firstname: "Ad",
                    lastname: "Soyad",
                    currentPassword: "Şuanki Şifre",
                    password: "Şifre",
                    passwordConfirmation: "Şifre Tekrar",
                    status: "Durum",
                    group: "Grup",
                    createdAt: "Oluşturulma Tarihi"
                },
                responseMessages: {
                    currentPasswordInvalid: "Şuanki şifre geçersiz."
                },
                group: {
                    title: "Yönetici Grupları",
                    subTitle: "Yönetici Grup Listesi",
                    newAdminGroup: "Yeni Grup",
                    editAdminGroup: "Grubu Düzenle",
                    clone: "Grubu Klonla",
                    cols: {
                        id: "ID",
                        code: "Kod",
                        name: "Adı",
                        status: "Durum"
                    },
                    endpoint: {
                        title: "İzin Yönetimi",
                        titlePattern: "%s - İzin Yönetimi"
                    }
                }
            },
            variable: {
                title: "Sistem Değişkenleri",
                new: "Yeni Sistem Değişkeni",
                edit: "Sistem Değişkeni Düzenleme",
                cols: {
                    code: "Kod",
                    val: "Değer",
                    description: "Açıklama"
                }
            },
            sms: {
                provider: {
                    title: "SMS Sağlayıcıları",
                    subTitle: "SMS Sağlayıcıları Listesi",
                    new: "Yeni SMS Sağlayıcı",
                    edit: "SMS Sağlayıcı Düzenle",
                    cols: {
                        code: "Kodu",
                        name: "Adı",
                        sort: "Sıra"
                    },
                    credential: {
                        title: "Kimlik"
                    },
                    responseMessages: {
                        invalid_handler: "Geçersiz sms sağlayıcı kodu girdiniz.",
                    }
                },
                multiSend: {
                    title: "Toplu SMS Gönderimi",
                    subTitle: "SMS Gönderim Listesi",
                    phoneList: "Telefon Listesi",
                    cols: {
                        message: "Mesaj",
                        sendDate: "Planlanan Gönderim",
                        itemCount: "Telefon Sayısı"
                    },
                    status:{
                        pending: "Bekliyor",
                        successful: "Başarılı",
                        failed: "Başarısız",
                        partiallySuccessful: "Kısmi Başarılı"
                    },
                    save: {
                        new: "Yeni Toplu SMS Gönderme",
                        cols: {
                            phone: "Telefon Listesi"
                        },
                        phoneCheck: {
                            title: "Telefon Denetleme Sonucu",
                            retryCheck: "Tekrar Telefon Denetle",
                            count: {
                                total: "Toplam",
                                correct: "Geçerli",
                                wrong: "Geçersiz"
                            }
                        },
                        detail: {
                            title: "Toplu SMS Gönderim Detayları"
                        }
                    },
                    warning:{
                        cannot_delete: "Bu kaydı silemezsiniz."
                    }
                }
            },
            linkManager: {
                title: "Bağlantılar",
                subTitle: "Bağlantı Listesi",
                editLink: "Bağlantı Düzenle",
                newRedirect: "Yeni 301 Yönlendirmesi",
                generalInformation: "Genel Bilgiler",
                seoSettings: "Seo Ayarları",
                cols: {
                    title: "Seo Başlığı",
                    type: "Tip",
                    slug: "Url"
                },
                seoCols: {
                    title: "Başlık",
                    description: "Açıklama",
                    keywords: "Anahtar Kelimeler",
                    noIndex: "Arama Motorlarında Gizle",
                    hideSitemap: "Site Haritasında Gizle"
                },
                redirectCols: {
                    slug: "Yönlendirilecek Url",
                    redirectSlug: "Yönlenecek Url"
                },
                responseMessages: {
                    link_already_exists: "Yönlenecek bağlantı zaten sistemde mevcuttur.",
                    only_redirect_links_can_deleted: "Sadece yönlenme bağlantıları silinebilir."
                }
            },
            setting: {
                title: "Sistem Ayarları",
                types: {
                    general: "Genel Ayarlar",
                    register: "Kayıt Ol Ayarları"
                }
            },
            siteSetting: {
                title: "Site Ayarları",
                types: {
                    general: "Genel Ayarlar",
                    homepage: "Anasayfa Ayarları"
                }
            },
            notification: {
                item: {
                    title: "Bildirim Alt Grup Öğeleri",
                    titlePattern: "%s - Öğeleri",
                    subTitle: "Öğe Listesi",
                    new: "Yeni Öğe",
                    edit: "Öğe Düzenle",
                    cols: {
                        code: "Kod",
                        name: "Ad"
                    },
                    group: {
                        title: "Bildirim Grupları",
                        subTitle: "Grup Listesi",
                        new: "Yeni Grup",
                        edit: "Grup Düzenle",
                        cols: {
                            code: "Kod",
                            name: "Ad"
                        }
                    },
                    subGroup: {
                        title: "Bildirim Alt Grupları",
                        titlePattern: "%s - Alt Grupları",
                        subTitle: "Alt Grup Listesi",
                        new: "Yeni Alt Grup",
                        edit: "Alt Grup Düzenle",
                        cols: {
                            code: "Kod",
                            name: "Ad"
                        }
                    },
                    template: {
                        title: "Bildirim Öğe Şablonları",
                        titlePattern: "%s - Şablonları",
                        tabs: {
                            general: "Genel",
                        },
                        warning: {
                            requiredContent: "Lütfen şablon içeriğini doldurun.",
                            variable: "Aşadağı değişkenleri %s örneği gibi kullanabilirsiniz."
                        },
                        cols: {
                            code: "Kod",
                            name: "Ad",
                            content: "İçerik"
                        }
                    }
                }
            }
        },
        ecommerce: {
            productManagement: {
                category: {
                    title: "Kategoriler",
                    subTitle: "Kategori Listesi",
                    newCategory: "Yeni Kategori",
                    newCategoryWithParent: "%s > Yeni Kategori",
                    editCategory: "%s - Kategori Düzenleme",
                    generalInformation: "Genel Bilgiler",
                    seoSettings: "Seo Ayarları",
                    cols: {
                        id: "ID",
                        title: "Başlık",
                        description: "Açıklama",
                        icon: "Ikon",
                        sort: "Sıra",
                        status: "Durum"
                    },
                    seoCols: {
                        slug: "Url",
                        title: "Başlık",
                        description: "Açıklama",
                        keywords: "Anahtar Kelimeler"
                    }
                },
                product: {
                    title: "Ürünler",
                    subTitle: "Ürün Listesi",
                    showCanUseModule: "Kullanabileceğiniz Modüller",
                    chooseModule: "Bir Modül Seçin",
                    cols: {
                        id: "ID",
                        title: "Başlık",
                        group: "Grup",
                        type: "Tip",
                        price: "Fiyat",
                        discountPrice: "İndr. Fiyat",
                        stockCode: "Stok Kodu",
                        stock: "Stok",
                        soldOutCount: "Satılan",
                        sort: "Sıra",
                        saleMinDate: "Min. Satış Saati"
                    },
                    save: {
                        newProduct: "Yeni Ürün",
                        editProduct: "%s - Ürün Düzenleme",
                        soldOutCount: "Satılan",
                        newTag: "Yeni Etiket",
                        tabs: {
                            generalDetails: "Genel Detaylar",
                            packageItemSettings: "Paket Ürün Ayarları",
                            addonSettings: "Eklenti Ayarları",
                            trailerSettings: "Tanıtım Video Ayarları",
                            variantSettings: "Varyant Ayarları",
                            customizePageSettings: "Sayfa Özelleştirme Ayarları",
                            lessonCalendarSettings: "Ders Takvimi",
                            documentSettings: "Döküman Ayarları",
                            invoiceProviderProductSettings: "Fatura Sağlayıcı Ürün Ayarı"
                        },
                        seoSettings: "Seo Ayarları",
                        cols: {
                            title: "Başlık",
                            description: "Açıklama",
                            content: "İçerik",
                            image: "Resim",
                            categories: "Kategoriler",
                            type: "Tip",
                            group: "Grup",
                            teachers: "Öğretmenler",
                            examDate: "Sınav Tarihi",
                            lessonType: "Ders Tipi",
                            price: "Fiyat",
                            taxes: "Vergiler",
                            discountPrice: "İndirimli Fiyatı",
                            saleMinDate: "Satış Başlangıç",
                            saleMaxDate: "Satış Bitiş",
                            usageType: "Kullanım Tipi",
                            usageMinDate: "Kullanım Başlangıç",
                            usageMaxDate: "Kullanım Bitiş",
                            shipping: "Kargo",
                            shippingPrice: "Kargo Fiyatı",
                            shippingWeight: "Kargo Desi",
                            stock: "Stok",
                            sort: "Sıra",
                            showInList: "Listede Göster",
                            showInPopular: "Popüler Ürün",
                            status: "Durum",
                            purchasedNotification: "Satın Alındı Bildirim Şablonu"
                        },
                        seoCols: {
                            slug: "Url",
                            title: "Başlık",
                            description: "Açıklama",
                            keywords: "Anahtar Kelimeler",
                            tags: "Etiketler"
                        },
                        shipping: {
                            system: "Sistem",
                            customPrice: "Özel Fiyat",
                            freeShipping: "Ücretsiz Kargo",
                            productWithoutShipping: "Kargosuz Ürün"
                        },
                        usageType: {
                            fixed_date: "Sabit Tarih",
                            custom_day: "Maksimum Gün"
                        },
                        informationBoxes: {
                            usageType: "Bu alanı Sabit Tarih olarak seçtiğinizde kullanım zamanını başlangıç ve bitiş olarak belirlersiniz, Maksimum Gün olarak seçildiğinde ise satın alma tarihinden itibaren kaç gün kullanılabileceğini belirtirsiniz. Tarih belirtmemeniz durumunda paketin tarihleri kullanılacaktır."
                        }
                    },
                    groupSettings: {
                        supportOptions: {
                            title: "Seçenekler"
                        },
                        supportDetails: {
                            title: "Diğer Detaylar"
                        }
                    },
                    packageItemSettings: {
                        title: "Paket Ürünler",
                        subTitle: "Paket Ürün Listesi",
                        newPackageItem: "Yeni Paket Ürün",
                        editPackageItem: "Paket Ürün Düzenleme",
                        cols: {
                            id: "ID",
                            packageItem: "Paket Ürün",
                            quantity: "Miktar",
                            price: "Fiyat",
                            discountPrice: "İndirimli Fiyatı",
                            usageType: "Kullanım Tipi",
                            usageMinDate: "Kullanım Başlangıç",
                            usageMaxDate: "Kullanım Bitiş"
                        },
                        usageType: {
                            fixed_date: "Sabit Tarih",
                            custom_day: "Maksimum Gün"
                        },
                        informationBoxes: {
                            usageType: "Bu alanı Sabit Tarih olarak seçtiğinizde kullanım zamanını başlangıç ve bitiş olarak belirlersiniz, Maksimum Gün olarak seçildiğinde ise satın alma tarihinden itibaren kaç gün kullanılabileceğini belirtirsiniz.",
                            price: "Fiyat alanı boş bırakıldığında ilgili ürünün fiyatı mevcut ürünün fiyatına eklenecektir. Fiyat girildiğinde ise ürün fiyatı girdiğiniz tutar üzerinden hesaplanacaktır. Ürünün ücretsiz olarak verilmesini istiyorsanız fiyat alanına 0 yazmanız gerekmektedir. Seçmiş olduğunuz ürünün varyantı varsa ve varyant için bir fiyat belirlemişseniz özel fiyatlandırma dikkate alınmayacaktır.",
                        }
                    },
                    addonSettings: {
                        title: "Eklenti Ürünler",
                        subTitle: "Eklenti Ürün Listesi",
                        newAddon: "Yeni Eklenti Ürün",
                        editAddon: "Eklenti Ürün Düzenleme",
                        cols: {
                            id: "ID",
                            addon: "Eklenti Ürün",
                            quantity: "Miktar",
                            price: "Fiyat",
                            discountPrice: "İndirimli Fiyatı",
                            invoicePrice: "Fatura Fiyatı",
                            autoAddCart: "Oto. Sepete Eklensin",
                            cannotRemoveCart: "Sepette Kaldırılmasın"
                        },
                        informationBoxes: {
                            price: "Fiyat alanı boş bırakıldığında ilgili ürünün fiyatı mevcut ürünün fiyatına eklenecektir. Fiyat girildiğinde ise ürün fiyatı girdiğiniz tutar üzerinden hesaplanacaktır. Ürünün ücretsiz olarak verilmesini istiyorsanız fiyat alanına 0 yazmanız gerekmektedir."
                        }
                    },
                    trailerSettings: {
                        title: "Tanıtım Videoları",
                        subTitle: "Tanıtım Video Listesi",
                        newTrailer: "Yeni Tanıtım Videosu",
                        editTrailer: "Tanıtım Videosu Düzenleme",
                        trailerTypes: {
                            main: "Ana",
                        },
                        cols: {
                            id: "ID",
                            trailerType: "Tanıtım Video Tipi",
                            createdAt: "Oluşturulma Tarihi",
                            providerData: {
                                api: "Sağlayıcı",
                                url: "Url",
                                server: "Sunucu",
                                accountId: "Hesap",
                                videoId: "Video ID"
                            }
                        },
                        responseMessages: {
                            sameTrailerTypeAlreadyExists: "Bu tanıtım video türü zaten daha önceden eklenmiş.",
                        }
                    },
                    variantSettings: {
                        title: "Varyantlar",
                        subTitle: "Varyant Listesi",
                        newVariant: "Yeni Varyant",
                        editVariant: "Varyant Düzenleme",
                        addNewVariant: "Yeni varyant ekle",
                        variantPattern: "%d. Variant",
                        cols: {
                            id: "ID",
                            stock: "Stok",
                            variant: "Varyant",
                            variantGroup: "Varyant Grubu",
                            variantItems: "Varyant Öğeleri",
                            price: "Fiyat",
                            discountPrice: "İndirimli Fiyatı",
                        },
                        responseMessages: {
                            combinationAlreadyExists: "Bu varyant zaten mevcut."
                        },
                        informationBoxes: {
                            price: "Oluşturduğunuz kombinasyona fiyat girmemeniz durumunda ürünün fiyatı varyant fiyatı olarak kabul edilecektir. Birden fazla varyant kombinasyonunuz olması durumunda ürün listesi ve ürün detay sayfasında en düşük fiyata sahip varyant kombinasyonunun fiyatı gösterilecektir.",
                        }
                    },
                    customizePageSettings: {
                        fields: {
                            title: "Başlık",
                            items: "Öğeler",
                            icon: "İkon",
                            description: "Açıklama",
                            poster: "Resim",
                            alert: "Uyarı Alanı",
                            content: "İçerik",
                            date: "Tarih",
                            rate: "Değerlendirme",
                            name: "Ad & Soyad",
                            data: ""
                        }
                    },
                    lessonCalendarSettings: {
                        emptyLesson:{
                            title: "1 Saatlik Ders",
                            subTitle: "Boş Ders",
                            buttonText: "Eklemek için takvime sürükleyin",
                        },
                        addRepeatLesson: "Haftalık Tekrarlayan Ders",
                        editLessonCalendar: "Ders Takvimi Düzenleme",
                        deleteAll: "Tüm Programları Sil",
                        cols: {
                            date: "Tarih",
                            teacher: "Öğretmen",
                            startDate: "Başl. Tarihi",
                            endDate: "Bitiş Tarihi",
                            startTime: "Başl. Saati",
                            endTime: "Bitiş Saati",
                            repeatCount: "Tekrar Sayısı"
                        },
                        warnings: {
                            time: "Başlangıç Saati, Bitiş Saat'inden sonra olamaz"
                        },
                        messages: {
                            title: "Dikkat Edilmesi Gerekenler",
                            message1: "Ders takvimi paket ürünlerde, paketin kendisine tanımlanmış özel bir ders takvimi tanımlanmamışsa eğer her bir paket öğesinin etkinlik takvimini birleştirip gösterecektir. Paket ürün için ders takvimi tanımlamanız durumunda sadece pakete tanımlanmış etkinlikler gösterilecektir.",
                            message2: "Etkinlik için öğretmen seçimi yapmamanız durumunda sistem ürüne tanımlanmış ilk öğretmen bilgisini kullanacaktır.",
                        },
                    }
                },
                variant: {
                    group: {
                        title: "Varyant Grupları",
                        subTitle: "Varyant Grup Listesi",
                        newVariantGroup: "Yeni Varyant Grubu",
                        editVariantGroup: "Varyant Grubunu Düzenle",
                        items: "Varyant Öğeleri",
                        cols: {
                            id: "ID",
                            code: "Kod",
                            name: "İsim",
                            allowedGroups: "İzin Verilen Gruplar",
                            sort: "Sıra",
                            status: "Durum"
                        }
                    },
                    item: {
                        title: "Varyant Öğeleri",
                        subTitle: "Varyant Öğe Listesi",
                        newVariantItem: "Yeni Varyant Öğesi",
                        editVariantItem: "Varyant Öğesini Düzenle",
                        cols: {
                            id: "ID",
                            code: "Kod",
                            name: "İsim",
                            sort: "Sıra",
                            status: "Durum"
                        }
                    }
                }
            },
            paymentMethod: {
                title: "Ödeme Yöntemleri",
                subTitle: "Ödeme Yöntemi Listesi",
                newPaymentMethod: "Yeni Ödeme Yöntemi",
                editPaymentMethod: "Ödeme Yöntemini Düzenle",
                cols: {
                    id: "ID",
                    type: "Tipi",
                    code: "Kodu",
                    name: "Adı",
                    sort: "Sıra",
                    status: "Durum"
                },
                credential: {
                    title: "Kimlik"
                },
                subMethod: {
                    title: "Alt Yöntemler",
                    titlePattern: "%s - Alt Yöntemleri",
                    subTitle: "Alt Yöntem Listesi",
                    newSubMethod: "Yeni Alt Yöntem",
                    editSubMethod: "Alt Yöntemi Düzenle",
                    cols: {
                        id: "ID",
                        sort: "Sıra",
                        status: "Durum"
                    },
                    payload: {
                        cols: {
                            code: "Kodu",
                            name: "Hesap Adı",
                            owner: "Hesap Sahibi",
                            accountNumber: "Hesap Numarası",
                            branch: "Şube",
                            branchNumber: "Şube Numarası",
                            iban: "IBAN"
                        }
                    }
                }
            },
            shippingMethod: {
                title: "Kargo Yöntemleri",
                subTitle: "Kargo Yöntemi Listesi",
                newShippingMethod: "Yeni Kargo Yöntemi",
                editShippingMethod: "Kargo Yöntemini Düzenle",
                cols: {
                    id: "ID",
                    code: "Kodu",
                    name: "Adı",
                    fixedPrice: "Sabit Fiyat",
                    freeShippingAmount: "Ücretsiz Kargo Alt Limiti",
                    freeShippingWeightLimit: "Ücretsiz Kargo Desi Limiti",
                    additionalWeightPrice: "Ek(+1) Desi Ücreti",
                    sort: "Sıra",
                    status: "Durum"
                },
                credential: {
                    title: "Kimlik"
                },
                rate: {
                    title: "Desi Fiyatlandırması",
                    titlePattern: "%s - Desi Fiyatlandırması",
                    subTitle: "Desi Fiyatlandırma Listesi",
                    newRate: "Yeni Desi Fiyatlandırması",
                    editRate: "Desi Fiyatlandırması Düzenle",
                    cols: {
                        id: "ID",
                        type: "Tip",
                        min: "Desi Min",
                        max: "Desi Max",
                        amount: "Ücret"
                    },
                    warnings: {
                        rate: "Minimum ve maksimum desi aralığına göre fiyatlandırma yapabilirsiniz. Örneğin 1 ile 3 arasında desiye sahip bir kargo için 15 TL kargo ücreti fiyatlandırması yapmak istiyorsanız “<b>Desi Min</b>” alanına 1, “<b>Desi Max</b>” alanına 3, “<b>Ücret</b>” alanına 15 yazmalısınız."
                    },
                    responseMessages: {
                        minGreaterMax: "Minimum değer, maksimum değerden küçük olmalıdır.",
                        minGreaterMaxOtherRecords: "Minimum değer, maksimum değerli diğer kayıtlardan büyük olmalıdır."
                    }
                }
            },
            order: {
                title: "Siparişler",
                subTitle: "Sipariş Listesi",
                orderStatus: "Sprş. Durumları",
                cols: {
                    id: "ID",
                    userName: "Üye Ad & Soyad",
                    userEmail: "Üye E-Posta",
                    orderTotal: "Sipariş Toplamı",
                    paidTotal: "Ödenen Tutar",
                    paymentMethod: "Ödeme Yöntemi",
                    orderStatus: "Sipariş Durumu",
                    shippingNumber: "Kargo Numarası",
                    createdAt: "Sipariş Tarihi"
                },
                save: {
                    newOrder: "Yeni Sipariş",
                    userSearch: "Ad veya soyad girin.",
                    product: {
                        title: "Ürünler",
                        addProduct: "Ürün Ekle",
                        newProduct: "Yeni Ürün",
                        productLabelPattern: "%s (%s)",
                        noStockProductLabelPattern: "%s (%s) (Stok yok)",
                        usageMinDateCheckText: "Başlangıç tarihini sistem belirlesin.",
                        usageMaxDateCheckText: "Bitiş tarihini sistem belirlesin.",
                        addon: {
                            title: "Eklentiler",
                            addableAddons: "Eklenebilir Eklentiler",
                            addonPattern: "%d. Eklenti (%s)",
                        },
                        packageItem: {
                            title: "Paket Ürünler",
                            packageItemPattern: "%d. Paket Ürün (%s)"
                        },
                        cols: {
                            title: "Ürün",
                            quantity: "Adet",
                            usageMinDate: "Kullanım Başlangıç",
                            usageMaxDate: "Kullanım Bitiş",
                        },
                        errors: {
                            occuredAnyProblem: "Bir problem oluştu, lütfen ürün eklemeyi tekrar deneyin."
                        }
                    },
                    cols: {
                        user: "Kullanıcı",
                        userAddress: "Kullanıcı Adresi",
                        orderStatus: "Sipariş Durumu"
                    },
                    warnings: {
                        items: "Lütfen ürün ekleyiniz."
                    },
                    responseMessages: {
                        packageInternalNoItem: "Paket üründe veya ürünlerde, ürün bulunmamaktadır."
                    }
                },
                detail: {
                    orderDetails: "Sipariş Detayları",
                    summary: {
                        subTotal: "Ara Toplam",
                        shipping: "Kargo",
                        tax: "Vergi",
                        installmentCommission: "Taksit Kom.",
                        total: "Toplam"
                    },
                    product: {
                        title: "Ürünler",
                        cols: {
                            name: "Adı",
                            type: "Tipi",
                            group: "Grubu",
                            quantity: "Adet",
                            price: "Fiyatı",
                            usageDate: "Kullanım Tarihleri",
                            usageMinDate: "Başl.",
                            usageMaxDate: "Bitiş",
                        }
                    },
                    orderAndPayment: {
                        title: "Sipariş & Ödeme",
                        cols: {
                            orderNumber: "Sipariş Numarası",
                            orderDate: "Sipariş Tarihi",
                            orderStatus: "Sipariş Durumu",
                            paymentMethodDetailFailed: "İşlem Hata Bilgisi",
                            payment: {
                                title: "Ödeme Bilgileri",
                                cols: {
                                    method: "Yöntem",
                                    paidTotal: "Ödenen Tutar",
                                    remainingCollectAmount: "Kalan Tutar",
                                    installment: "Taksit Sayısı",
                                    installmentCommission: "Taksit Komisyonu",
                                    subMethod: {
                                        cols: {
                                            name: "Banka Adı",
                                            accountNumber: "Hesap No"
                                        }
                                    },
                                    paymentDetail: {
                                        bank: "Banka",
                                        creditCard: {
                                            owner: "Kart Sahibi",
                                            number: "Kart No"
                                        }
                                    }
                                }
                            },
                            shipping: {
                                title: "Kargo Bilgileri",
                                cols: {
                                    name: "Şirket",
                                    number: "Kargo Numarası",
                                    price: "Kargo Ücreti"
                                }
                            }
                        }
                    },
                    user: {
                        title: "Müşteri",
                        cols: {
                            identificationNumber: "Kimlik No",
                            address: {
                                title: "Adres Bilgileri",
                                cols: {
                                    invoiceType: "Fatura Tipi",
                                    name: "Ad & Soyad",
                                    identificationNumber: "Kimlik No",
                                    address: "Adres",
                                    district: "Mahalle",
                                    location: "İlçe / İl",
                                    zipCode: "Posta Kodu",
                                    phone: "Telefon",
                                    companyName: "Şirket Adı",
                                    companyTaxOffice: "Şirket Vergi D."
                                }
                            }
                        }
                    },
                    admin: {
                        title: "Sipariş Oluşturan"
                    },
                    itemUsageDate: {
                        editItemUsageDatePattern: "%s - Kullanım Tarihi Düzenleme",
                        cols: {
                            usageMinDate: "Kullanım Başlangıç",
                            usageMaxDate: "Kullanım Bitiş",
                        }
                    }
                },
                status: {
                    title: "Sipariş Durumları",
                    subTitle: "Sipariş Durum Listesi",
                    newOrderStatus: "Yeni Sipariş Durumu",
                    editOrderStatus: "Sipariş Durumunu Düzenle",
                    cols: {
                        id: "ID",
                        code: "Kod",
                        name: "Adı"
                    }
                },
                paymentCollection: {
                    title: "Ödeme Tahsilatları",
                    subTitle: "Ödeme Tahsilat Listesi",
                    newPaymentCollection: "Yeni Ödeme Tahsilatı",
                    transferRemainingAmount: "Kalan Tutarı Aktar",
                    totalAmount: "Toplam Tutar",
                    remainingTotal: "Kalan Tutar",
                    cols: {
                        createdBy: "Tahsilatı Oluşturan",
                        amount: "Tutar",
                        note: "Not",
                        createdAt: "Oluşturulma Tarihi"
                    },
                    responseMessages: {
                        amountMustLessThanRemaining: "Girdiğiniz tutar, kalan tutardan küçük olmalıdır."
                    }
                },
                note: {
                    title: "Sipariş Notları",
                    subTitle: "Sipariş Not Listesi",
                    newOrderNote: "Yeni Sipariş Notu",
                    cols: {
                        createdBy: "Notu Oluşturan",
                        note: "Not",
                        createdAt: "Oluşturulma Tarihi"
                    }
                },
                discountCoupon: {
                    title: "İndirim Kuponları",
                    subTitle: "İndirim Kuponları Listesi",
                    cols: {
                        type: "Tip",
                        total: "Uygulanan İndirim",
                        code: "Kupon Kodu",
                        value: "Değer"
                    }
                },
                creator: {
                    title: "Toplu Sipariş",
                    subTitle: "Toplu Sipariş Listesi",
                    exportFilter: "Dışa Aktarma",
                    cols: {
                        creator: "Oluşturan",
                        totalUser: "Toplam Kullanıcı",
                        productCount: "Ürün Sayısı",
                        success: "Başarılı",
                        completedAt: "Tamamlanma",
                    },
                    status:{
                        pending: "Bekliyor",
                        processing: "İşlemde",
                        completed: "Bitti"
                    },
                    exportStatus:{
                        pending: "Bekliyor",
                        created: "Oluşturuldu",
                        already_exist: "Zaten Mevcut",
                        error: "Hata"
                    },
                    messages: {
                        export: "İsterseniz durum bilgisine göre filtreleme sağlayabilirsiniz. Bir durum seçmediğiniz taktirde tüm kayıtlar dışa aktarılacaktır.",
                    },
                    detail: {
                        title: "Diğer Detaylar",
                        productTitlePattern: "%s - Ürünü",
                        packageItemTitlePattern: "%d. Paket Ürün",
                        addonTitlePattern: "%d. Eklenti",
                        packageItems: "Paket İtemler",
                        addons: "Eklentiler",
                        cols: {
                            quantity: "Adet",
                            title: "Ad",
                            usageMinDate: "Kullanım Başlangıç",
                            usageMaxDate: "Kullanım Bitiş",
                        }
                    },
                    save: {
                        new: "Yeni Toplu Sipariş Tanımlama",
                        cols: {
                            user: "Kullanıcı Listesi"
                        },
                        userCheck: {
                            title: "Kullanıcı Denetleme Sonucu",
                            retryCheck: "Tekrar Kullanıcı Denetle",
                            count: {
                                total: "Toplam",
                                available: "Sistemde Kayıtlı",
                                unavailable: "Sistemde Kayıtsız veya Geçersiz"
                            }
                        }
                    }
                },
                shipping: {
                    title: "Lojistik İşlemleri",
                    subTitle: "Lojistik İşlemleri Listesi",
                    cols: {
                        orderNo: "Sipariş No",
                        user: "Kullanıcı",
                        shipping: "Kargo",
                        shippingTrackingNumber: "Kargo Takip No",
                        shippingStatus: "Sağl. Durum",
                        orderDate: "Sipariş Tarihi"
                    },
                    selectedRowEvents: {
                        sendShipping: "Kargo Çıkışı",
                        printShipping: "Kargo Bilg. Yazdır"
                    },
                    shippingStatus:{
                        pending: "Bekliyor",
                        manuelSend: "Manuel Gönderim",
                        tobeProcessed: "İşlenecek",
                        processed: "İşlendi",
                        shipped: "Kargolandı",
                        delivered: "Teslim Edildi",
                        error: "Hata"
                    },
                    responseErrors: {
                        this_order_already_prepared: "Bu sipariş zaten hazırlandı."
                    },
                    preview: {
                        cols: {
                            address: {
                                title: "Alıcı Bilgileri",
                                cols: {
                                    fullName: "İsim",
                                    phone: "Telefon",
                                    address: "Adres"
                                }
                            },
                            shippingDetail: {
                                title: "Kargo Bilgileri",
                                cols: {
                                    paymentType: "Ödeme Türü",
                                    shippingMethod: "Kargo Firması",
                                    shippingType: "Kargo Tipi",
                                    shippingBarcodeNo: "Kargo Barkod No"
                                }
                            },
                            item: {
                                title: "Ürün Bilgileri",
                                cols: {
                                    image: "Fotoğraf",
                                    name: "Ürün Adı"
                                }
                            },
                        },
                        shippingTypeValue: "Gönderici Ödemeli Kargo",
                    },
                    detail: {
                        title: "Kargo Detayları",
                        receivedStatus: {
                            yes: "Teslim Edildi",
                            no: "Henüz Teslim Edilmedi"
                        },
                        cols: {
                            orderNo: "Sipariş No",
                            received: "Durum",
                            receivingPerson: "Teslim Alan",
                            receivingDate: "Teslim Alınan Tarih",
                        },
                        movement: {
                            title: "Kargo Hareketleri",
                            status: {
                                accepted: "Kabul Edildi",
                                atSenderBranch: "Gönderici Şubesinde",
                                onTheRoad: "Yolda",
                                atReceiverBranch: "Alıcı Şubesinde",
                                delivered: "Teslim Edildi"
                            }
                        },
                    }
                },
            },
            orderManagement: {
                changeUsageMulti: {
                    title: "Sip. Kul. Tarihi Değiştirme Aracı",
                    subTitle: "Değiştirme Görevi Listesi",
                    status: {
                        pending: "Bekliyor",
                        processing: "İşleniyor",
                        completed: "Tamamlandı",
                        cancelled: "İptal Edildi",
                        error: "Hata Oluştu"
                    },
                    allOrders: "Tüm Siparişlerde",
                    cols:{
                        admin: "Yönetici",
                        payload: {
                            order: "Sipariş",
                            product: "Ürün ID",
                            justWithoutPackage: "Paket Haricinde Satılanlar",
                            startProcessingNow: "İşleme Şimdi Başla"
                        },
                        state: "Durum",
                    },
                    detail: {
                        title: "İşlem Detayları",
                        productTitle: "Ana Ürün Detayları",
                        processDetail: "İşlem Detayı",
                        packageItemTitlePattern: "%s. Paket Ürün",
                        cols: {
                            product: "Ürün",
                            usageMinDate: "Kullanım Başlangıç",
                            usageMaxDate: "Kullanım Bitiş",
                            justWithoutPackage: "Paket Haricinde Satılanlar",
                            justWithoutUsage: "Kullanım Tarihi Yoksa Değiştir",
                            order: {
                                status: "Sipariş Durumları",
                                count: "İşlem Yapılan Sipariş Sayısı",
                                itemCount: "İşlem Yapılan Sipariş Öğesi"
                            },
                            targetAdmin: {
                                title: "Hedef Yönetici",
                                description: "Bu işlem emri sadece %s yöneticisinin tanımladığı siparişler için gerçekleşecektir."
                            }
                        }
                    },
                    save: {
                        newChangeUsageMulti: "Yeni Tarih Değiştirme Emri",
                        startProcessingNowWarning: "İşlem hemen başlatmak istediğinize emin misiniz?",
                        cols: {
                            admin: "Hedef Yönetici",
                            product: "Ürün",
                            order: "Sipariş",
                            orderStatus: "Sipariş Durumları",
                            usageMinDate: "Kullanım Başlangıç",
                            usageMaxDate: "Kullanım Bitiş",
                            justWithoutPackage: "Paket Haricinde Satılanlar",
                            justWithoutUsage: "Kullanım Tarihi Yoksa Değiştir",
                            startProcessingNow: "İşleme Şimdi Başla"
                        },
                        packageItem: {
                            title: "Paket Ürünler",
                            subTitle: "Paket Ürün Listesi",
                            newPackageItem: "Yeni Paket Ürün",
                            editPackageItem: "Paket Ürün Düzenleme",
                            cols: {
                                packageItem: "Paket Ürün",
                                usageMinDate: "Kullanım Başlangıç",
                                usageMaxDate: "Kullanım Bitiş",
                                justWithoutUsage: "Kul. Tar. Yoksa Değiştir",
                            }
                        },
                        informationBoxes: {
                            order: "Eğer sipariş numarası girerseniz o siparişte işlem yapılır, boş bırakırsanız tüm siparişlerde işlem yapılır.",
                            justWithoutPackage: "Bu seçenek aktif edildiğinde sadece paket dışında satılmış olan siparişlerde değişim sağlanacaktır. Paket içerisinde bulunan ürünler etkilenmeyecektir.",
                            startProcessingNow: "Bu seçenek aktif edildiğinde işleme hemen, kapalı olduğu durumda ise işlem 5 dakika sonra başlatılır.",
                            packageItem: "Aşağıdaki alanda paket ürün seçimi yapmamanız durumunda paket için belirlemiş olduğunuz kullanım başlangıç ve bitiş tarihleri sadece paketin kendisi için geçerli olacaktır.",
                            admin: "Bir yönetici seçimi yaparsanız eğer sadece seçili olan yöneticinin tanımladığı siparişlerde değişiklik yapılacaktır."
                        },
                        "alert": {
                            "package_item": {
                                "title": "<b>Dikkat!</b> - Paket ürün seçimi yaptınız!",
                                "description": "Paket ürünlerde, paketin içerisinde bulunan öğelerin tarihini değiştirmek istiyorsanız aşağıdaki alandan öğeyi eklemeniz ve <b>tarih belirlemeniz</b> gerekmektedir. Paket içinde bulunan öğenin tarihinin paket tarihi ile aynı olmasını istiyorsanız aşağıdaki alandan öğeyi eklemeniz ancak kullanım başlangıç ve bitiş tarihlerini <b>boş bırakmanız</b> gerekmektedir.<br><br>Aşağıdaki alanda bir paket öğesi <b>eklememeniz</b> durumunda <b>sadece paketin</b> kullanım başlangıç ve bitiş tarihleri güncellenecektir."
                            }
                        }
                    }
                }
            },
            user: {
                title: "Kullanıcılar",
                subTitle: "Kullanıcı Listesi",
                newUser: "Yeni Kullanıcı",
                editUser: "Kullanıcı Düzenle",
                editUserPattern: "%s - Kullanıcı Düzenleme",
                changePassword: "Şifre Değiştirme",
                selfLogin: "Hesaba Giriş Yap",
                createOrder: "Sipariş Oluştur",
                bougthProducts: "Satın Aldığı Ürünler",
                examResult: "Sınav Sonuçları",
                isAbroad: "Yurt dışında mı?",
                cols: {
                    id: "ID",
                    name: "Ad & Soyad",
                    user: "Kullanıcı",
                    gender: "Cinsiyet",
                    firstname: "Ad",
                    lastname: "Soyad",
                    birthDate: "Doğum Tarihi",
                    email: "E - Posta",
                    order: "Sipariş",
                    identificationNumber: "Kimlik Numarası",
                    identificationNumberShort: "TC",
                    mobilePhone: "Cep Telefonu",
                    password: "Şifre",
                    passwordConfirmation: "Şifre Tekrar",
                    isAbroad: "Yurt Dışı",
                    status: "Durum"
                },
                address: {
                    title: "Kullanıcı Adresleri",
                    subTitle: "Kullanıcı Adres Listesi",
                    newAddress: "Yeni Adres",
                    editAddress: "Adresi Düzenle",
                    searchCountry: "Lütfen arama için ülke adı girin.",
                    searchState: "Lütfen arama için il adı girin.",
                    searchCity: "Lütfen arama için ilçe adı girin.",
                    cols: {
                        id: "ID",
                        type: "Tip",
                        title: "Başlık",
                        country: "Ülke",
                        state: "İl",
                        city: "İlçe",
                        district: "Mahalle",
                        zipCode: "Posta Kodu",
                        identificationNumber: "Kimlik Numarası",
                        taxNumber: "Vergi Numarası",
                        mobilePhone: "Cep Telefonu",
                        address: "Adres",
                        firstname: "Ad",
                        lastname: "Soyad",
                        companyName: "Şirket Adı",
                        companyTaxOffice: "Şirket Vergi Dairesi",
                        defaultAddress: "Varsayılan Adres"
                    },
                },
                creator: {
                    title: "Toplu Kullanıcı",
                    subTitle: "Toplu Kullanıcı Listesi",
                    new: "Yeni Toplu Kullanıcı Tanımlama",
                    exportFilter: "Dışa Aktarma",
                    cols: {
                        upload: {
                            title: "Şablon",
                            dragFile: "Şablonu buraya sürükleyin veya yüklemek için tıklayın"
                        },
                        creator: "Oluşturan",
                        totalUser: "Toplam Kullanıcı",
                        success: "Başarılı",
                        completedAt: "Tamamlanma",
                    },
                    status:{
                        pending: "Bekliyor",
                        processing: "İşlemde",
                        completed: "Bitti"
                    },
                    exportStatus:{
                        pending: "Bekliyor",
                        created: "Oluşturuldu",
                        already_exist: "Zaten Mevcut",
                        error: "Hata"
                    },
                    messages: {
                        title: "Dikkat Edilmesi Gerekenler",
                        template: "Toplu kullanıcı eklemek için sistem şablonunu kullanmalısınız. Şablonu indirmek için <a href='%s'>buraya</a> tıklayınız.",
                        export: "İsterseniz durum bilgisine göre filtreleme sağlayabilirsiniz. Bir durum seçmediğiniz taktirde tüm kayıtlar dışa aktarılacaktır.",
                        message1: "Şablon içerisinde bulunan sütunların yerini değiştirmeyiniz, yeni sütun eklemeyeniz, biçim ve formatlama türünü değiştirmeyiniz.",
                        message2: "Ad, Soyad, E-Posta ve Telefon alanları zorunlu alanlardır. Bu alanlar haricinde diğer alanlar isteğe bağlıdır.",
                        message3: "Telefon numarasını +<b>90</b>5XX veya <b>0090</b>5XX formatında girebilirsiniz.",
                        message4: "Cinsiyet girişi yapmak isterseniz erkek için <b>E</b> kadın için <b>K</b> harflerini, giriş yapmak istemiyorsanız [-] <b>sadece tire</b> koyunuz.",
                        message5: "Şifre belirtmek istiyorsanız ilgili alanı doldurunuz. Şifre alanını boş bırakmanız durumunda telefon numarasının son 6 hanesi şifre olarak belirlenecektir.",
                        message6: "Yurtdışı Evet/Hayır girişi yapmak isterseniz <b>E</b> ve <b>H</b> harflerini kullanınız. Giriş yapmak istemiyorsanız [-] <b>sadece tire</b> koyunuz.",
                        message7: "Kullanıcı kayıt işlemi arka planda otomatik olarak gerçekleşecektir. Bu işlem, kullanıcı sayısına bağlı olarak birkaç dakika kadar sürebilir.",
                    },
                    warnings: {
                        requiredFile: "Lütfen şablon seçin veya sürükleyin."
                    }
                },
                save: {
                    tabs: {
                        generalDetails: "Genel Bilgiler",
                        addressSettings: "Adresler",
                        orderSettings: "Siparişler",
                        statistic: "İstatistikler",
                        historyLogin: "Oturum Geçmişi"
                    },
                },
                history: {
                    login: {
                        title: "Oturum Geçmişi",
                        subTitle: "Oturum Geçmiş Listesi",
                    }
                }
            },
            discountCoupon: {
                title: "İndirim Kuponları",
                subTitle: "İndirim Kupon Listesi",
                newDiscountCoupon: "Yeni İndirim Kuponu",
                editDiscountCoupon: "İndirim Kuponu Düzenle",
                generateCode: "Kodu yeniden üret.",
                exportTooltip: "Filtreleme yapılmadığında liste maksimum 2500 kayıt olarak sınırlandırılacaktır.",
                couponTypes: {
                    static: "Sabit",
                    percent: "Yüzdesel"
                },
                types: {
                    single: "Tekli",
                    multi: "Çoklu"
                },
                cols: {
                    code: "Kod",
                    type: "Kupon Oluşturma Tipi",
                    count: "Kupon Sayısı",
                    couponType: "Kupon Tipi",
                    value: "Değer",
                    used: "Kullanılan",
                    startDate: "Başlangıç Tarihi",
                    endDate: "Bitiş Tarihi",
                    totalUseLimit: "Limit",
                    userUseLimit: "Kullanıcı Başına Limit",
                    minAmount: "Minimum Tutar",
                    users: "Kullanıcılar",
                    cannotCombined: "Diğer Kuponlarla Birleştirilebilir",
                    product: "Ürün",
                    usable: {
                        usableProduct: "Sadece Seçili Ürünlerde Kullanılsın",
                        unusableProduct: "Seçili Ürünlerde Kullanılmasın",
                        usableCategory: "Sadece Seçili Kategorilerde Kullanılsın",
                        unusableCategory: "Seçili Kategorilerde Kullanılmasın"
                    },
                },
                tabs: {
                    general: "Genel Ayarlar",
                    product: "Ürün Ayarları",
                    user: "Kullanıcı Ayarları",
                },
                warnings: {
                    userUseLimit: "Kullanıcı limiti, toplam limitten büyük olamaz."
                },
                informationBoxes: {
                    product: "Bu indirim kuponunun sadece belirli ürünler sepete eklendiğinde geçerli olması için ürün seçimi yapabilirsiniz.",
                    user: "Kullanıcı seçimi yapıldığında bu indirim kodu sadece seçili kullanıcılar tarafından kullanılabilecektir.",
                    userUseLimit: "Bir kullanıcının indirim kodunu kaç kere kullanabileceğini belirtebilirsiniz. Sınırsız olmasını istiyorsanız boş bırakın.",
                    usable: {
                        usableProduct: "Bu indirim kuponu sadece seçili ürünlerde kullanılabilir olması için ürün seçimi yapabilirsiniz.",
                        unusableProduct: "Bu indirim kuponu seçili ürünlerde kullanılmaması için ürün seçimi yapabilirsiniz.",
                        usableCategory: "Bu indirim kuponu sadece seçili kategorilerde kullanılabilir olması için kategori seçimi yapabilirsiniz.",
                        unusableCategory: "Bu indirim kuponu seçili kategorilerde kullanılmaması için kategori seçimi yapabilirsiniz."
                    },
                },
                creator: {
                    title: "Otomatik Kupon Tanımlama",
                    subTitle: "Otomatik Kupon Listesi",
                    new: "Yeni Otomatik Kupon Tanımlama",
                    edit: "Otomatik Kupon Tanımlama Düzenleme",
                    cols: {
                        product: "Ürünler",
                        coupon: "Kupon",
                        options: {
                            beforeOrderDate: "Şu Sipariş Tarihinden Öncesi",
                            afterOrderDate: "Şu Sipariş Tarihinden Sonrası",
                        }
                    }
                },
            },
            invoiceManagement: {
                invoice: {
                    title: "Faturalar",
                    subTitle: "Fatura Listesi",
                    cols: {
                        id: "ID",
                        orderNumber: "Sipariş No",
                        user: "Kullanıcı",
                        identificationNumber: "TC",
                        total: "Ftr. / Alınan Tutar",
                        providerInvoice: "Sağl. Durum",
                        providerInvoiceID: "Sağl. Ftr. ID",
                        providerLegalizeStatus: "Resml. Durum",
                        status: "Durumu",
                        createdAt: "Oluşturulma"
                    },
                    status: {
                        notPaid: "Ödenmedi",
                        paid: "Ödendi",
                        cancelled: "İptal Edildi"
                    },
                    providerInvoice: {
                        processed: "İşlendi",
                        waiting: "Bekliyor"
                    },
                    providerLegalizeStatus: {
                        notStart: "Başlamadı",
                        pending: "Bekliyor",
                        success: "Tamamlandı",
                        error: "Hata"
                    },
                    showLegalizedPdf: {
                        responseMessages: {
                            notCreated: "İlgili fatura henüz resmileştirilmemiş, lütfen biraz sonra tekrar deneyiniz.",
                        }
                    },
                    preview: {
                        cols: {
                            order: {
                                id: "Sipariş No",
                                date: "Sipariş Tarihi"
                            },
                            address: {
                                invoiceType: "Fatura Tipi",
                                companyName: "Ticari Ünvan",
                                companyTaxOffice: "Vergi Dairesi"
                            },
                            user: {
                                fullName: "Müşteri Ünvan",
                                identificationNumber: "VKN",
                                state: "İl",
                                city: "İlçe",
                                district: "Mahalle",
                                address: "Adres"
                            },
                            item: {
                                id: "O.Item Id",
                                name: "Ürün Adı",
                                unitPrice: "Vergisiz Birim Fiyat",
                                quantity: "Miktar",
                                tax: "Vergi",
                                taxes: "Vergi(ler)",
                                total: "Toplam"
                            },
                            discountCoupon: {
                                code: "İndirim Kuponu",
                                type: "İndirim Tipi",
                                value: "İndirim Değeri",
                                total: "Yapılan İndirim"
                            },
                            total: {
                                subTotal: "Vergisiz Toplam",
                                tax: "Toplam Vergi",
                                total: "Toplam Tutar"
                            }
                        }
                    },
                    providerErrors:{
                        title: "Sağlayıcı Hataları",
                        errorTitlePattern: "%s. Hata Detayları",
                        cols:{
                            createdAt: "Hata Zamanı",
                            errorMessage: "Hata Kodu",
                            errorMessageHuman: "Hata Açıklaması",
                            errorMessageTricks: "Hata Giderme İpuçları",
                            provider: "Sağlayıcı"
                        }
                    }
                },
                provider: {
                    title: "Fatura Sağlayıcıları",
                    subTitle: "Fatura Sağlayıcı Listesi",
                    newProvider: "Yeni Fatura Sağlayıcı",
                    editProvider: "Fatura Sağlayıcı Düzenle",
                    cols: {
                        id: "ID",
                        code: "Kodu",
                        name: "Adı",
                        status: "Durum"
                    },
                    credential: {
                        title: "Kimlik"
                    },
                    setting: {
                        title: "Ayarlar",
                        use_custom_stock: "Özel Stok Kullan",
                        synchronization: "Senkronizasyon"
                    },
                    responseMessages: {
                        providerHandlerNotFoundByCode: "Koda göre sağlayıcı bulunamadı.",
                        mustHaveLeastOneActiveProvider: "En az bir sağlayıcı aktif olmalı.",
                        supportError: "Bu sağlayıcı, yapmak istediğiniz işlemi desteklememektedir."
                    },
                    paymentMethod: {
                        title: "Banka Hesapları",
                        titlePattern: "%s - Banka Hesapları",
                        subTitle: "Banka Hesapları Listesi",
                        newPaymentMethod: "Yeni Banka Hesabı",
                        editPaymentMethod: "Banka Hesabı Düzenle",
                        cols: {
                            id: "ID",
                            paymentMethod: "Ödeme Yöntemi",
                            paymentSubMethod: "Alt Yöntem",
                            providerAccountID: "Sağlayıcı Hesap Kimliği"
                        }
                    },
                    product: {
                        title: "Ürünler",
                        titlePattern: "%s - Ürünler",
                        subTitle: "Ürün Listesi",
                        editPattern: "%s - Ürün Eşleştirme",
                        cols: {
                            title: "Ürün Adı",
                            providerProductTitle: "Ürün Adı (Sağlayıcı)",
                            matchState: "Eşleşme Durumu"
                        },
                        matchStates: {
                            full: "Eşleştirildi",
                            partial: "Kısmi",
                            none: "Eşleştirilmedi"
                        },
                        variantCombination: {
                            title: "Ürüne Ait Varyant Listesi"
                        },
                        createProduct: {
                            product: "Bu ürünü fatura sağlayıcısında oluştur",
                            variant: "Bu ürünü ve varyantı sağlayıcıda oluştur"
                        },
                        warnings: {
                            requiredProviderProduct: "Lütfen sağlayıcı ürün seçimi yapın."
                        },
                        errors: {
                            could_not_fetch_provider_product: "Sağlayıcıdan ürün listesi alınırken, sistemsel sorun oluştu.",
                            could_not_fetch_product: "Ürün detayı alınırken, sistemsel sorun oluştu.",
                            could_not_fetch_product_relation: "Sağlayıcı ile eşlenmiş ürün listesi alınırken, sistemsel sorun oluştu."
                        }
                    }
                },
            }
        },
        localization: {
            tax: {
                title: "Vergiler",
                subTitle: "Vergi Listesi",
                newTax: "Yeni Vergi",
                editTax: "Vergi Düzenle",
                cols: {
                    id: "ID",
                    code: "Kod",
                    country: "Ülke",
                    name: "Adı",
                    rate: "Oran",
                    sort: "Sıra",
                    status: "Durum"
                }
            },
        },
        media: {
            title: "Medyalar",
            newMedia: "Yeni Medya",
            upload: {
                title: "Dosyalar",
                dragFile: "Dosyayı buraya sürükleyin veya yüklemek için tıklayın"
            },
            showingPagePattern: "%s kayıttan, %d ile %d arası gösteriliyor",
            warnings: {
                files: "Lütfen yüklemek istediğiniz dosyaları seçin veya sürükleyin."
            },
            responseMessages: {
                invalidFileType: "Geçersiz dosya türü veya türleri mevcuttur."
            }
        },
        module: {
            examDate: {
                title: "Sınav Tarihleri",
                subTitle: "Sınav Tarihleri Listesi",
                newExamDate: "Yeni Sınav Tarihi",
                editExamDate: "Sınav Tarihini Düzenle",
                cols: {
                    id: "ID",
                    name: "Adı",
                    date: "Sınav Tarihi",
                    createdAt: "Oluşturulma Tarihi"
                }
            },
            lessonType: {
                title: "Ders Türleri",
                subTitle: "Ders Türleri Listesi",
                newLessonType: "Yeni Ders Türü",
                editLessonType: "Ders Türü Düzenle",
                cols: {
                    id: "ID",
                    name: "Adı",
                    createdAt: "Oluşturulma Tarihi"
                }
            },
            teacher: {
                title: "Öğretmenler",
                subTitle: "Öğretmen Listesi",
                newTeacher: "Yeni Öğretmen",
                editTeacher: "Öğretmen Düzenle",
                cols: {
                    id: "ID",
                    name: "Ad & Soyad",
                    branch: "Branş",
                    image: "Resim",
                    firstname: "Ad",
                    lastname: "Soyad",
                    identificationNumber: "Kimlik Numarası",
                    birthDate: "Doğum Tarihi",
                    email: "E - Posta",
                    mobilePhone: "Cep Telefonu",
                    address: "Adres",
                    description: "Açıklama",
                    sort: "Sıra",
                    status: "Durum"
                },
                branch: {
                    title: "Branşlar",
                    subTitle: "Branş Listesi",
                    newBranch: "Yeni Branş",
                    editBranch: "Branş Düzenle",
                    cols: {
                        id: "ID",
                        name: "Adı",
                        createdAt: "Oluşturulma Tarihi"
                    },
                },
                trailer: {
                    title: "Tanıtım Videoları",
                    titlePattern: "%s - Tanıtım Videoları",
                    subTitle: "Tanıtım Video Listesi",
                    newTrailer: "Yeni Tanıtım Videosu",
                    editTrailer: "Tanıtım Videosu Düzenleme",
                    trailerTypes: {
                        main: "Özgeçmiş",
                        how_does_teach: "Nasıl ders anlatıyor"
                    },
                    cols: {
                        id: "ID",
                        trailerType: "Tanıtım Video Tipi",
                        createdAt: "Oluşturulma Tarihi",
                        providerData: {
                            api: "Sağlayıcı",
                            url: "Url",
                            server: "Sunucu",
                            accountId: "Hesap",
                            videoId: "Video ID"
                        }
                    },
                    responseMessages: {
                        sameTrailerTypeAlreadyExists: "Bu tanıtım video türü zaten daha önceden eklenmiş.",
                    }
                },
            },
            lecture: {
                title: "Konu Anlatımları",
                titlePattern: "%s - Konu Anlatımları",
                subTitle: "Konu Anlatım Listesi",
                cols: {
                    id: "ID",
                    title: "Başlık",
                    description: "Açıklama",
                    content: "İçerik",
                    sort: "Sıra",
                    status: "Durum"
                },
                selectProduct: {
                    title: "Ürün Seçimi",
                    chooseProduct: "Ürün seçimi yapınız.",
                    cols: {
                        product: "Ürün"
                    }
                },
                notFoundProduct: "Konu anlatımı ekleyebileceğiniz bir ürün bulunamadı!",
                save: {
                    newLecture: "Yeni Konu Anlatımı (%s)",
                    editLecture: "%s - Konu Anlatımı Düzenle (%s)",
                    newLectureWithFolder: "%s > Yeni Konu Anlatımı",
                    tabs: {
                        generalDetails: "Genel Detaylar",
                        streamSettings: "Video Ayarları",
                        documentSettings: "Döküman Ayarları"
                    },
                    cols: {
                        title: "Başlık",
                        description: "Açıklama",
                        content: "İçerik",
                        sort: "Sıra",
                        status: "Durum"
                    }
                },
                streamSettings: {
                    title: "Videolar",
                    subTitle: "Video Listesi",
                    newStream: "Yeni Video",
                    editStream: "Video Düzenleme",
                    streamTypes: {
                        live: "Canlı",
                        record: "Kayıttan",
                        live_record: "Canlı Ders Kaydı"
                    },
                    providers: {
                        ADOBE_CONNECT: "Adobe Connect",
                        ADOBE_CONNECT2: "Adobe Connect (Geçici)"
                    },
                    cols: {
                        id: "ID",
                        teacher: "Öğretmen",
                        image: "Video Kapak Resim",
                        type: "Video Türü",
                        date: "Video Tarihi",
                        activityDate: "Etkinlik Tarihi",
                        streamData: {
                            api: "Sağlayıcı",
                            url: "Url",
                            server: "Sunucu",
                            zoom: "Zoom Toplantıları",
                            accountId: "Hesap",
                            videoId: "Video ID",
                            userGroup: "Kullanıcı Grubu"
                        }
                    },
                    requiredActivityDate: "Etkinlik Tarihi alanını lütfen doldurun.",
                    responseMessages: {
                        sameStreamTypeAlreadyExists: "Bu video türü zaten daha önceden eklenmiş.",
                        videoIdInvalid: "Geçersiz video kimliği girdiniz."
                    }
                },
                directory: {
                    title: "Klasörler",
                    subTitle: "Klasör Listesi",
                    newDirectory: "Yeni Klasör",
                    newDirectoryWithParent: "%s > Yeni Klasör",
                    editDirectory: "%s - Klasör Düzenleme",
                    cols: {
                        id: "ID",
                        title: "Başlık",
                        sort: "Sıra"
                    },
                },
                directoryMode: {
                    newDirectory: "Yeni Klasör",
                    newLecture: "Yeni Konu Anlatımı",
                    chooseAction: "İşlem Seçin",
                    documentCount: "Döküman Sayısı",
                    newAddQuestion: "Klasöre hangisini eklemek istiyorsunuz ?"
                }
            },
            document: {
                title: "Dökümanlar",
                subTitle: "Döküman Listesi",
                newDocument: "Yeni Döküman",
                editDocument: "Döküman Düzenleme",
                previewDocument: "Dökümanı Görüntüle",
                upload: {
                    title: "Döküman",
                    dragFile: "Dosyayı buraya sürükleyin veya yüklemek için tıklayın"
                },
                cols: {
                    id: "ID",
                    name: "Ad",
                    type: "Tip",
                    documentName: "Döküman Adı",
                    documentExtension: "Döküman Uzantısı",
                    documentSize: "Döküman Boyutu",
                    createdAt: "Oluşturulma Tarihi"
                },
                types: {
                    single: "Tekli",
                    multi: "Çoklu"
                },
                warnings: {
                    requiredDocument: "Lütfen döküman seçin veya sürükleyin."
                }
            },
            branchCourse: {
                title: "Şube Dersleri",
                managementTitle: "Şube Dersleri Yönetimi",
                menu: {
                    branch: "Şube & Dersler",
                    reservation: "Rezervasyonlar",
                    block: "Engellenen Kullanıcılar"
                },
                titlePattern: "%s - Şube Dersleri",
                subTitle: "Şube Ders Listesi",
                newBranchCourse: "Yeni Şube Dersi",
                editBranchCourse: "Şube Dersi Düzenle",
                reservations: "Rezervasyonlar",
                cols: {
                    id: "ID",
                    branch: "Şube",
                    image: "Resim",
                    title: "Başlık",
                    reservationCount: "Rezervasyon",
                    description: "Açıklama",
                    teacher: "Öğretmen",
                    startDate: "Başlangıç Tarihi",
                    endDate: "Bitiş Tarihi",
                    previewStartDate: "Gösterim Başlangıç",
                    previewEndDate: "Gösterim Bitiş",
                    lessonDay: "Ders Günleri",
                    lessonHour: "Ders Saati",
                    quota: "Kota",
                    fakeQuota: "Fazladan Gösterim",
                    sort: "Sıra",
                    status: "Durum"
                },
                branch: {
                    title: "Şubeler",
                    subTitle: "Şube Listesi",
                    newBranch: "Yeni Şube",
                    editBranch: "Şube Düzenle",
                    cols: {
                        id: "ID",
                        title: "Başlık",
                        sort: "Sıra",
                        status: "Durum"
                    }
                },
                reservation: {
                    title: "Rezervasyonlar",
                    subTitle: "Rezervasyon Listesi",
                    editReservation: "Rezervasyon Düzenle",
                    reservationDetails: "Rezervasyon Detayları",
                    back: "Geri Dön",
                    blockUser: {
                        title: "Kullanıcıyı Engelle",
                        cols: {
                            reason: "Engelleme Nedeni",
                            blockedAt: "Engelleme Bitiş"
                        },
                        informationBoxes: {
                            blockedAt: "Bir tarih belirlerseniz, kullanıcı o tarihe kadar engellenir, eğer belirlemezseniz süresiz olarak engellenir."
                        }
                    },
                    unblockUser: "Kullanıcı Engelini Kaldır",
                    cols: {
                        id: "ID",
                        branchCourse: "Şube Ders",
                        user: "Kullanıcı",
                        name: "İsim",
                        email: "E - Posta",
                        mobilePhone: "Telefon",
                        ip: "IP",
                        userAgent: "User Agent",
                        approved: "Onaylandı",
                        createdAt: "Oluşturulma Tarihi"
                    }
                },
                block: {
                    title: "Engellenen Kullanıcılar",
                    subTitle: "Engellenen Kullanıcı Listesi",
                    new: "Yeni Kullanıcı Engelle",
                    cols: {
                        user: "Kullanıcı",
                        admin: "Engelleyen",
                        reason: "Engelleme Nedeni",
                        date: "Tarih",
                        blockedAt: "Engelleme Bitiş",
                    },
                    informationBoxes: {
                        blockedAt: "Bir tarih belirlerseniz, kullanıcı o tarihe kadar engellenir, eğer belirlemezseniz süresiz olarak engellenir."
                    }
                }
            },
            outQuestion: {
                title: "Çıkmış Sorular",
                titlePattern: "%s - Çıkmış Sorular",
                subTitle: "Çıkmış Soru Listesi",
                newOutQuestion: "Yeni Çıkmış Soru",
                editOutQuestion: "Çıkmış Soru Düzenle",
                showDocument: "Dokümanı Görüntüle",
                showDownload: "İndirilebilir",
                cols: {
                    id: "ID",
                    category: "Kategori",
                    title: "Başlık",
                    sort: "Sıra",
                    showDownload: "İndirme Butonunu Göster",
                    showToolbar: "Araç Çubuğunu Göster",
                    status: "Durum"
                },
                informationBoxes: {
                    showDownload: "PDF görüntüleyicinin altında bulunan indirme butonunu aktif ya da pasif hale getirir. PDF görüntüleyicide bulunan İndirme & Yazdırma vb. gibi işlemleri kapatmak istiyorsanız 'Araç Çubuğunu Göster' ayarını kapalı konuma getiriniz.",
                    showToolbar: "PDF görüntüleyici için araç çubuğunu gösterir ya da gizler."
                },
                upload: {
                    title: "Döküman",
                    dragFile: "Dosyayı buraya sürükleyin veya yüklemek için tıklayın"
                },
                warnings: {
                    requiredDocument: "Lütfen döküman seçin veya sürükleyin."
                },
                category: {
                    title: "Kategoriler",
                    subTitle: "Kategori Listesi",
                    newCategory: "Yeni Kategori",
                    newCategoryWithParent: "%s > Yeni Kategori",
                    editCategory: "%s - Kategori Düzenleme",
                    generalInformation: "Genel Bilgiler",
                    seoSettings: "Seo Ayarları",
                    subCategory: "Alt Kategori",
                    cols: {
                        id: "ID",
                        title: "Başlık",
                        description: "Açıklama",
                        sort: "Sıra",
                        status: "Durum"
                    },
                    seoCols: {
                        slug: "Url",
                        title: "Başlık",
                        description: "Açıklama",
                        keywords: "Anahtar Kelimeler"
                    }
                }
            },
            blog: {
                title: "Bloglar",
                subTitle: "Blog Listesi",
                cols: {
                    id: "ID",
                    title: "Başlık"
                },
                save: {
                    newBlog: "Yeni Blog",
                    editBlog: "%s - Blog Düzenleme",
                    seoSettings: "Seo Ayarları",
                    cols: {
                        title: "Başlık",
                        description: "Açıklama",
                        content: "İçerik",
                        image: "Resim",
                        categories: "Kategoriler",
                    },
                    seoCols: {
                        slug: "Url",
                        title: "Başlık",
                        description: "Açıklama",
                        keywords: "Anahtar Kelimeler"
                    }
                },
                category: {
                    title: "Kategoriler",
                    subTitle: "Kategori Listesi",
                    newCategory: "Yeni Kategori",
                    editCategory: "%s - Kategori Düzenleme",
                    generalInformation: "Genel Bilgiler",
                    seoSettings: "Seo Ayarları",
                    cols: {
                        id: "ID",
                        title: "Başlık",
                        description: "Açıklama"
                    },
                    seoCols: {
                        slug: "Url",
                        title: "Başlık",
                        description: "Açıklama",
                        keywords: "Anahtar Kelimeler"
                    }
                },
            },
            popup: {
                title: "Popuplar",
                subTitle: "Popup Listesi",
                cols: {
                    title: "Başlık",
                    content: "İçerik",
                    popupType: "Popup Tipi",
                    startDate: "Başl. Tarihi",
                    endDate: "Bitiş Tarihi",
                    showTimeout: "Gecikme Süresi",
                    repeat: "Tekrar Süresi",
                    sticky: "Yapışkan Mod",
                    stickyShowTime: "Gösterim Süresi",
                    allowDontShow: "Tekrar Gösterme",
                    hideHeader: "Başlığı Gizle",
                    hideFooter: "Footer'ı Gizle"
                },
                save: {
                    newPopup: "Yeni Popup",
                    editPopup: "%s - Popup Düzenleme",
                    tabs: {
                        generalDetails: "Genel Detaylar",
                        buttonSettings: "Buton Ayarları",
                    },
                    responseMessages: {
                        contentCannotBeBothShownAndHidden: "Gösterilecek ve gizlenecek sayfalarda, aynı içerik bulunamaz.",
                        typeIdAlreadyExists: "Gösterilecek veya gizlenecek sayfalarda, zaten aynı içerik mevcut.",
                        cannotEnterRecordWhenAllSelect: "Gösterilecek veya gizlenecek sayfalarda, hepsini göster veya gizle seçili olduğunda; belirli bir içeriği ekleyemezsiniz."
                    }
                },
                status: {
                    secondText: "%d sn.",
                    once: "Bir kez",
                    continually: "Sürekli",
                    now: "Hemen"
                },
                popupTypes: {
                    image: "Resim",
                    content : "İçerik"
                },
                visibility: {
                    all: "Hepsi",
                    cols: {
                        type: "Tip",
                        title: "Başlık",
                        assigned: "Sayfa"
                    },
                    client: "Öğrenci Paneli",
                    show: {
                        title: "Görüntülenecek Sayfalar",
                        subTitle: "Görüntülenecek Sayfa Listesi",
                        new: "Yeni Görüntülenecek Sayfa",
                    },
                    hide: {
                        title: "Gizlenecek Sayfalar",
                        subTitle: "Gizlenecek Sayfa Listesi",
                        new: "Yeni Gizlenecek Sayfa",
                    },
                    informationBoxes: {
                        showAssigned: "Sayfa seçimi yapmanız durumunda Pop-up sadece seçtiğiniz sayfalarda gösterilir. Seçim yapmamanız durumunda tüm sayfalarda gösterilecektir.",
                        hideAssigned: "Sayfa seçimi yapmanız durumunda Pop-up sadece seçtiğiniz sayfalarda gizlenecektir. Seçim yapmamanız durumunda tüm sayfalarda gizlenecektir."
                    }
                },
                informationBoxes: {
                    showTimeout: "Pop-up'ın kullanıcıya kaç saniye sonra gösterilmesini belirtebilirsiniz. Boş bırakmanız durumunda Pop-up hemen gösterilecektir.",
                    repeat: "Pop-up'ın kullanıcıya kaç saniyede bir gösterileceğini belirtebilirsiniz. Boş bırakmanız durumunda Pop-up kullanıcıya sadece 1 kez gösterilecek, 0 girmeniz durumunda ise sürekli gösterilecektir.",
                    sticky: "Yapışkan mod aktif edildiğinde belirlediğiniz süre dolmadan kullanıcı Pop-up ekranını kapatamaz. Süre dolduğunda Pop-up otomatik olarak kapatılır.",
                    stickyShowTime: "Pop-up'ın saniye cinsinden ne kadar ekranda duracağını belirtebilirsiniz.",
                    allowDontShow: "Tekrar gösterme seçeneği aktif edilirse, kullanıcıya çıkan Pop-up'ta bir daha gösterme seçeneği gösterilir."
                },
                buttonSettings: {
                    title: "Popup Butonları",
                    titlePattern: "%s - Popup Butonları",
                    subTitle: "Popup Buton Listesi",
                    newButton: "Yeni Popup Buton",
                    editButton: "Popup Buton Düzenleme",
                    cols: {
                        id: "ID",
                        title: "Başlık",
                        slug: "Url"
                    }
                },
            },
            sliderGroup: {
                title: "Slider Grupları",
                subTitle: "Slider Grup Listesi",
                newSliderGroup: "Yeni Slider Grup",
                editSliderGroup: "Slider Grup Düzenle",
                cols: {
                    alias: "Takma Adı"
                }
            },
            sliderItem: {
                title: "Sliderlar",
                titlePattern: "%s - Sliderları",
                subTitle: "Slider Listesi",
                cols: {
                    title: "Başlık",
                    startDate: "Başl. Tarihi",
                    endDate: "Bitiş Tarihi",
                },
                save: {
                    newSliderItem: "%s - Yeni Slider",
                    editSliderItem: "%s - Slider Düzenleme",
                    tabs: {
                        generalDetails: "Genel Detaylar",
                        buttonSettings: "Buton Ayarları",
                    },
                    cols: {
                        shortTitle: "Kısa Başlık",
                        description: "Açıklama",
                        slug: "Url",
                        image: "Resim"
                    },
                    warnings: {
                        requiredImage: "Lütfen resim seçin."
                    }
                },
                buttonSettings: {
                    title: "Slider Butonları",
                    titlePattern: "%s - Slider Butonları",
                    subTitle: "Slider Buton Listesi",
                    newButton: "Yeni Slider Buton",
                    editButton: "Slider Buton Düzenleme",
                    cols: {
                        title: "Başlık",
                        icon: "İkon",
                        slug: "Url"
                    }
                },
            },
            examManagement: {
                title: "Genel Deneme Yönetimi",
                menu: {
                    exam: "Sınavlar",
                    question: "Sorular",
                    gain: "Kazanımlar",
                    examSessionRecord: "Sınav Oturum Kayıtları"
                },
                exam: {
                    title: "Sınavlar",
                    subTitle: "Sınav Listesi",
                    settings: "Genel Deneme Ayarları",
                    cols: {
                        title: "Başlık",
                        examDate: "Sınav Tarihi",
                        examDuration: "Sınav Süresi (dk.)",
                        questionCount: "Soru Sayı Limiti",
                        questionRelationCount: "Ekli Soru Sayısı",
                        sessionCount: "Oturum Sayısı",
                        withoutSession: "Oturumsuz mu?",
                        totalUserSessionCount: "Katılımcı Sayısı",
                    },
                    save: {
                        newExam: "Yeni Sınav",
                        editExam: "%s - Sınav Düzenleme",
                        usageType: {
                            everyone: "Herkes",
                            ownProduct: "Belirli Ürünlere Sahip Olanlar"
                        },
                        cols: {
                            description: "Açıklama",
                            category: "Kategori",
                            templateType: "Şablon Tipi",
                            questionType: "Soru Tipi",
                            deductedWrong: "Yanlışın Doğruyu Götürme Sayısı",
                            usageType: "Kullanım Tipi",
                            withoutSession: "Oturumsuz Sınav",
                            products: "Ürünler",
                            usageMinDate: "Gösterim Başlangıç",
                            usageMaxDate: "Gösterim Bitiş"
                        },
                        tabs: {
                            generalDetails: "Genel Detaylar",
                            sessionSettings: "Oturum Ayarları",
                            questionRelationSettings: "Soru Ayarları"
                        },
                        informationBoxes: {
                            deductedWrong: "Sınav sonuçlandırması sağlanırken kaç yanlışın kaç doğruyu götüreceğini belirtebilirsiniz. Yanlış cevapların doğruyu cevapları götürmesini istemiyorsanız eğer bu alanı 0 giriniz.",
                            usageMaxDate: "Gösterim Bitiş tarihi sınav oturumunun bitiş tarihini belirtmemektedir. Gösterim Başlangıç ve Bitiş tarihleri bu sınavın hangi tarihler arasında Sınavlar listesinde gösterileceğini ve gösterimden kalkacağını belirtir. Gösterim Bitiş tarihi sona erse bile öğrenci sınavdaysa oturum süresi bitesiye kadar sınavda kalacaktır."
                        },
                    },
                    category: {
                        title: "Kategoriler",
                        subTitle: "Kategori Listesi",
                        newCategory: "Yeni Kategori",
                        newCategoryWithParent: "%s > Yeni Kategori",
                        editCategory: "%s - Kategori Düzenleme",
                        subCategory: "Alt Kategori",
                        cols: {
                            title: "Başlık",
                        }
                    },
                    session: {
                        title: "Sınav Oturumları",
                        subTitle: "Sınav Oturum Listesi",
                        newSession: "Yeni Sınav Oturumu",
                        editSession: "Sınav Oturumu Düzenleme",
                        cols: {
                            sessionDate: "Oturum Tarihi",
                            sessionTime: "Oturum Saati",
                            quota: "Kota",
                            fakeQuota: "Sahte Kota",
                            realRemainingQuota: "Kalan Gerçek Kota"
                        },
                        warnings: {
                            exam_duration: "Oturum tarihi, diğer oturum tarihleri ile arasında sınav süresi kadar fark olmadılır."
                        },
                        user: {
                            title: "Sınav Oturum Kayıtları",
                            subTitle: "Sınav Oturum Kayıtları Listesi",
                            reservation: "Rezervasyon",
                            reservationDate: "Rez. Trh",
                            direct: "Direkt",
                            sureFinishSession: "Oturumu sonlandırmak istediğinize emin misiniz?",
                            sureRetryFinishSession: "Bu oturumu yeniden sonuçlandırmak istediğinize emin misiniz?",
                            cols: {
                                user: "Kullanıcı",
                                examInfo: "Sınav Bilgisi",
                                recordType: "Kayıt Tipi",
                                date: "Başlangıç / Bitiş",
                                answered: "Cevaplanan"
                            }
                        }
                    },
                    questionRelation: {
                        title: "%s - Soruları",
                        subTitle: "Eklenmiş Soru Listesi",
                        addQuestion: "Soruyu Ekle",
                        removeQuestion: "Soruyu Çıkar",
                        sureRemoveQuestion: "Soruyu çıkarmak istediğinize emin misiniz?",
                        sureAddQuestion: "Soruyu eklemek istediğinize emin misiniz?",
                        questionFilter: {
                            title: "Soru Filtrele",
                            cols: {
                                category: "Kategori",
                                questionType: "Soru Tipi",
                                magic: "Soru Adı veya Cevap",
                                gain: "Kazanımlar"
                            }
                        },
                        responseMessages: {
                            youHaveReachedMaxQuestionAddLimit: "Maksimum ekleyebileceğin soru limitine ulaştın."
                        }
                    },
                    finalize: {
                        title: "Sınav Sonuçlandırma",
                        sureQuestion: "Sınavı sonuçlandırmak istediğinize emin misiniz?",
                        cols: {
                            finalizeMethod: "Sonuçlandırma Yöntemi",
                            session: "Oturumlar"
                        },
                        finalizeMethods: {
                            justEnded: "Sadece Bitenleri Sonuçlandır",
                            willChoose: "Oturumları Ben Seçeceğim"
                        },
                        informationBoxes: {
                            session: "Eğer oturum seçimi yaparsanız, sadece o oturumlar sonuçlandırılır, Eğer oturum seçmezseniz, tüm oturumlar sonuçlandırılır."
                        },
                        responseMessages: {
                            allExamSessionsCompletedInOrderFinalized: "Sonuçlandırma işleminin yapılabilmesi için tüm sınav oturumlarının bitmesi gerekmektedir!",
                            examHasNotResultSessions: "Bu sınavda, sonuçlandırılacak oturum mevcut değildir."
                        }
                    },
                    cloneQuestion: {
                        title: "Soruları Klonla",
                        newCloneQuestion: "Yeni Soruları Klonlama",
                        cloneType: {
                            overwrite: "Olmayan Soruları Ekle",
                            flush: "Hepsini Sil ve Yeniden Ekle"
                        },
                        cols: {
                            source: "Kaynak Sınav",
                            cloneType: "Klonlama Tipi"
                        },
                        submitClone: "Klonla",
                        responseMessages: {
                            questionCannotChangedWhileStartExam: "Sınav başlamış veya başlamak üzereyken, sorular değiştirilemez."
                        }
                    },
                    result: {
                        title: "Öğrenci Sınav Sonuçları",
                        subTitle: "Öğrenci Sınav Sonuç Listesi",
                        cols: {
                            user: "Kullanıcı",
                            examInfo: "Sınav Bilgisi",
                            total: "Toplam",
                            answered: "Cevapladığı",
                            unanswered: "Cevaplamadığı",
                            correct: "Doğru",
                            wrong: "Yanlış",
                            net: "Net",
                            grade: "Puan",
                            spendedTime: "Harcadığı Süre"
                        },
                        detail: {
                            questionInfo: {
                                questionCount: "Soru Sayısı",
                                time: "Süre",
                                grade: "Puan",
                                spendedTime: "Harcadığı Süre"
                            }
                        },
                        send: {
                            title: "Sonuçları Gönder",
                            cols: {
                                sendType: "Gönderme Tipi"
                            },
                            sendType: {
                                mail: "E-Posta",
                                sms: "Sms"
                            },
                            sendResultWarning: "Sınav sonuçlarının tüm öğrencilere iletilme süresi sınava giren öğrenci sayısına, sunucu yoğunluğuna ve mail sunucularının yük durumuna bağlı olarak değişkenlik gösterecek olup her öğrenciye teslim edilme süresi 1 saat kadar sürebilir."
                        }
                    },
                },
                question: {
                    title: "Sorular",
                    subTitle: "Soru Listesi",
                    cols: {
                        content: "İçerik",
                        type: "Tip",
                        answerCount: "Cevap Sayısı",
                        correctAnswer: "Doğru Cevap"
                    },
                    save: {
                        newQuestion: "Yeni Soru",
                        editQuestion: "Soru Düzenleme",
                        cols: {
                            category: "Kategori",
                            type: "Soru Tipi",
                            externalAnswerCount: "Cevap Sayısı",
                            gain: "Kazanımlar"
                        },
                        tabs: {
                            generalDetails: "Genel Detaylar",
                            solutionSettings: "Çözüm Ayarları"
                        },
                    },
                    answer: {
                        title: "Cevaplar",
                        titlePattern: "%s Şıkkı",
                        newAnswer: "Yeni Cevap",
                        cols: {
                            content: "İçerik"
                        }
                    },
                    solution: {
                        title: "Çözüm",
                        cols: {
                            content: "İçerik",
                            streamProviderData: {
                                api: "Sağlayıcı",
                                accountId: "Hesap",
                                videoID: "Video ID"
                            }
                        },
                        warnings: {
                            required_content_or_stream_provider: "Lütfen içerik veya stream alanını doldurun."
                        },
                        responseMessages: {
                            videoIdInvalid: "Video numarası geçersiz.",
                         }
                    },
                    category: {
                        title: "Kategoriler",
                        subTitle: "Kategori Listesi",
                        newCategory: "Yeni Kategori",
                        newCategoryWithParent: "%s > Yeni Kategori",
                        editCategory: "%s - Kategori Düzenleme",
                        subCategory: "Alt Kategori",
                        cols: {
                            title: "Başlık",
                        }
                    },
                },
                gain: {
                    title: "Kazanımlar",
                    subTitle: "Kazanım Listesi",
                    newGain: "Yeni Kazanım",
                    editGain: "Kazanım Düzenle",
                    cols: {
                        title: "Başlık"
                    },
                },
            },
            voiceRecording: {
                title: "Ses Kayıtları",
                titlePattern: "%s - Ses Kayıtları",
                subTitle: "Ses Kayıtları Listesi",
                newVoiceRecording: "Yeni Ses Kaydı",
                editVoiceRecording: "Ses Kaydını Düzenle",
                cols: {
                    category: "Kategori",
                    title: "Başlık",
                    sort: "Sıra",
                    status: "Durum"
                },
                upload: {
                    title: "Ses",
                    dragFile: "Dosyayı buraya sürükleyin veya yüklemek için tıklayın"
                },
                warnings: {
                    requiredSound: "Lütfen ses dosyası seçin veya sürükleyin."
                },
                category: {
                    title: "Kategoriler",
                    subTitle: "Kategori Listesi",
                    newCategory: "Yeni Kategori",
                    newCategoryWithParent: "%s > Yeni Kategori",
                    editCategory: "%s - Kategori Düzenleme",
                    generalInformation: "Genel Bilgiler",
                    seoSettings: "Seo Ayarları",
                    subCategory: "Alt Kategori",
                    cols: {
                        title: "Başlık",
                        description: "Açıklama"
                    }
                }
            },
            paragraph: {
                title: "Paragraflar",
                subTitle: "Paragraf Listesi",
                cols: {
                    title: "Başlık",
                    readingTime: "Okuma Zamanı (dk.)"
                },
                save: {
                    newParagraph: "Yeni Paragraf",
                    editParagraph: "%s - Paragraf Düzenleme",
                    newTag: "Yeni Etiket",
                    level: {
                        easy: "Kolay",
                        medium: "Orta",
                        hard: "Zor"
                    },
                    cols: {
                        content: "İçerik",
                        tags: "Etiketler",
                        level: "Seviye",
                        image: "Resim"
                    },
                    tabs: {
                        generalDetails: "Genel Detaylar",
                        translationSettings: "Çeviri Ayarları",
                        questions: "Sorular"
                    },
                    translateCodes: {
                        original: "Orjinal",
                        translated: "Çevirisi"
                    },
                    responseMessages: {
                        contentCannotBeBothShownAndHidden: "Gösterilecek ve gizlenecek sayfalarda, aynı içerik bulunamaz.",
                        typeIdAlreadyExists: "Gösterilecek veya gizlenecek sayfalarda, zaten aynı içerik mevcut.",
                        cannotEnterRecordWhenAllSelect: "Gösterilecek veya gizlenecek sayfalarda, hepsini göster veya gizle seçili olduğunda; belirli bir içeriği ekleyemezsiniz."
                    },
                    editSentence:{
                        title: "Paragraf Ayarları",
                        chooseAction: "İşlem Seçin",
                        setSentence: "Cümle belirleyin.",
                        editSentenceDescription: "Düzenlemeyi kaydetmek için Enter, iptal etmek için ESC tuşuna basınız.",
                        sentenceMenu: {
                            newSentenceBefore: "Yeni Cümle (Başa)",
                            newSentenceAfter: "Yeni Cümle (Sona)",
                            edit: "Düzenle",
                            newLine: "Yeni Satır",
                        }
                    },
                    translation: {
                        title: "Çeviri Ayarları"
                    }
                },
                question: {
                    title: "Sorular",
                    subTitle: "Soru Listesi",
                    cols: {
                        content: "İçerik",
                        type: "Tip",
                        answerCount: "Cevap Sayısı",
                        correctAnswer: "Doğru Cevap"
                    },
                    save: {
                        newQuestion: "Yeni Soru",
                        editQuestion: "Soru Düzenleme",
                        cols: {
                            type: "Soru Tipi",
                            externalAnswerCount: "Cevap Sayısı",
                            gain: "Kazanımlar"
                        },
                    },
                    answer: {
                        title: "Cevaplar",
                        titlePattern: "%s Şıkkı",
                        newAnswer: "Yeni Cevap",
                        cols: {
                            content: "İçerik"
                        }
                    },
                },
            },
            zoom: {
                title: "Zoom",
                meeting: {
                    title: "Zoom Toplantıları",
                    subTitle: "Zoom Toplantı Listesi",
                    newMeeting: "Yeni Toplantı",
                    generateCode: "Şifre yeniden üret.",
                    cols: {
                        meetingID: "Toplantı ID",
                        topic: "Konu",
                        meetingType: "Toplantı Türü",
                        startDate: "Başl. Tarihi",
                        duration: "Süre",
                        registrantCount: "Katılımcı",
                        host: "Host",
                        password: "Şifre",
                        agenda: "Gündem",
                        approvalType: "Katılımcı Onayı"
                    },
                    approvalTypes:{
                        manualApproval: "Manuel Onay",
                        noApprovalRequired: "Onay Gerektirmesin"
                    },
                    informationBoxes: {
                        approvalType: "Toplantıya sadece satın alım yapan kullanıcıların katılmasını istiyorsanız 'Manuel Onay', herkesin katılabilmesini istiyorsanız 'Onay Gerektirmesin' seçeceğini seçiniz.",
                    },
                    responseMessages: {
                        meetingCannotBePast: "Toplantı başlangıç tarihi, şuanki tarihden önce olamaz."
                    },
                    detail: {
                        titlePattern: "%s - Detayları",
                        cols: {
                            remoteHostID: "Remote Host ID",
                            endDate: "Bitiş Tarihi",
                            encryptedPassword: "Şifrelenmiş (Şifre)",
                            uuid: "Uuid",
                            startUrl: "Başl. Url",
                            joinUrl: "Katılma Url"
                        },
                        tabs: {
                            generalDetails: "Genel Detaylar",
                            registrantSettings: "Katılımcılar",
                        },
                        host: {
                            title: "Host Detayları",
                        },
                        registrant: {
                            title: "Katılımcılar",
                            subTitle: "Katılımcı Listesi",
                            copyJoinUrl: "Katılım Url'ini Kopyala",
                            cols: {
                                user: "Kullanıcı"
                            }
                        }
                    },
                    recording: {
                        title: "Toplantı Kayıtları",
                        cols: {
                            fileType: "Dosya Türü",
                            format: "Format",
                            size: "Boyut",
                            download: "İndir",
                        },
                        responseMessages: {
                            meetingRecordingFetchError: "Üzgünüz, etkinlik kaydına ulaşılamadı."
                        }
                    }
                },
                host: {
                    title: "Zoom Host",
                    subTitle: "Zoom Host Listesi",
                    newHost: "Yeni Host",
                    editHost: "Host Düzenleme",
                    cols: {
                        host: "Host",
                        name: "Ad",
                        accountID: "Account ID",
                        apiKey: "Api Key or Api ID",
                        apiSecret: "Api Secret"
                    },
                }
            },
            tracking: {
                title: "İzleme Kodları",
                subTitle: "İzleme Kod Listesi",
                cols: {
                    name: "Ad",
                    location: "Konum"
                },
                save: {
                    newTracking: "Yeni İzleme Kodu",
                    editTracking: "%s - İzleme Kodu Düzenleme",
                    cols: {
                        content: "İçerik"
                    },
                    visibility: {
                        all: "Hepsi",
                        cols: {
                            type: "Tip",
                            title: "Başlık",
                            assigned: "Sayfa"
                        },
                        show: {
                            title: "Eklenecek Sayfalar",
                            subTitle: "Eklenecek Sayfa Listesi",
                            new: "Yeni Eklenecek Sayfa",
                        },
                        hide: {
                            title: "Eklenmeyecek Sayfalar",
                            subTitle: "Eklenmeyecek Sayfa Listesi",
                            new: "Yeni Eklenmeyecek Sayfa",
                        },
                        specialLinkTypes: {
                            page404: "404 Sayfası",
                            page500: "500 Sayfası",
                        },
                        informationBoxes: {
                            showAssigned: "Sayfa seçimi yapmanız durumunda İzleme Kodu sadece seçtiğiniz sayfalara eklenir. Seçim yapmamanız durumunda tüm sayfalara eklenir.",
                            hideAssigned: "Sayfa seçimi yapmanız durumunda İzleme Kodu sadece seçtiğiniz sayfalarda eklenmez."
                        }
                    },
                    responseMessages: {
                        contentCannotBeBothShownAndHidden: "Gösterilecek ve gizlenecek sayfalarda, aynı içerik bulunamaz.",
                        typeIdAlreadyExists: "Gösterilecek veya gizlenecek sayfalarda, zaten aynı içerik mevcut.",
                        cannotEnterRecordWhenAllSelect: "Gösterilecek veya gizlenecek sayfalarda, hepsini göster veya gizle seçili olduğunda; belirli bir içeriği ekleyemezsiniz."
                    }
                }
            },
            source: {
                title: "Kaynaklar",
                titlePattern: "%s - Kaynakları",
                subTitle: "Kaynak Listesi",
                new: "Yeni Kaynak",
                edit: "Kaynak Düzenleme",
                showDocument: "Dokümanı Görüntüle",
                showDownload: "İndirilebilir",
                cols: {
                    title: "Başlık",
                    options: {
                        showDownload: "İndirme Butonunu Göster",
                        showToolbar: "Araç Çubuğunu Göster",
                    },
                },
                informationBoxes: {
                    showDownload: "PDF görüntüleyicinin altında bulunan indirme butonunu aktif ya da pasif hale getirir. PDF görüntüleyicide bulunan İndirme & Yazdırma vb. gibi işlemleri kapatmak istiyorsanız 'Araç Çubuğunu Göster' ayarını kapalı konuma getiriniz.",
                    showToolbar: "PDF görüntüleyici için araç çubuğunu gösterir ya da gizler."
                },
                upload: {
                    title: "Döküman",
                    dragFile: "Dosyayı buraya sürükleyin veya yüklemek için tıklayın"
                },
                warnings: {
                    requiredDocument: "Lütfen döküman seçin veya sürükleyin."
                },
                group: {
                    title: "Kaynak Grupları",
                    subTitle: "Kaynak Grup Listesi",
                    new: "Yeni Kaynak Grup",
                    edit: "Kaynak Grubu Düzenleme",
                    cols: {
                        alias: "Takma Ad",
                        name: "Ad"
                    }
                },
                category: {
                    title: "Kategoriler",
                    titlePattern: "%s - Kategorileri",
                    subTitle: "Kategori Listesi",
                    newCategory: "Yeni Kategori",
                    newCategoryWithParent: "%s > Yeni Kategori",
                    editCategory: "%s - Kategori Düzenleme",
                    generalInformation: "Genel Bilgiler",
                    seoSettings: "Seo Ayarları",
                    cols: {
                        title: "Başlık",
                        description: "Açıklama"
                    },
                    seoCols: {
                        slug: "Url",
                        title: "Başlık",
                        description: "Açıklama",
                        keywords: "Anahtar Kelimeler"
                    }
                },
            },
            featuredProduct: {
                title: "Öne Çıkan Ürünler",
                subTitle: "Öne Çıkan Listesi",
                new: "Yeni Öne Çıkan Ürün",
                edit: "Öne Çıkan Ürün Düzenleme",
                cols: {
                    product: "Ürün",
                    watchTag: "İzleme Etiketi",
                    description: "Açıklama"
                },
                group: {
                    title: "Öne Çıkan Ürün Grupları",
                    subTitle: "Öne Çıkan Ürün Grup Listesi",
                    cols: {
                        name: "Ad",
                    },
                    save: {
                        new: "Yeni Öne Çıkan Ürün Grubu",
                        edit: "%s - Öne Çıkan Ürün Grubu Düzenleme",
                        cols: {
                            title: "Başlık",
                            description: "Açıklama"
                        },
                        tabs: {
                            generalDetails: "Genel Bilgiler",
                            productSettings: "Ürünler"
                        },
                    }
                }
            },
            push: {
                title: "Bildirim Yönetimi",
                menu: {
                    notification: "Bildirimler",
                    provider: "Bildirim Sağlayıcıları"
                },
                notification: {
                    title: "Bildirimler",
                    subTitle: "Bildirim Listesi",
                    new: "Yeni Bildirim",
                    show: "Bildirim Detayları",
                    cols: {
                        provider: "Sağlayıcı",
                        pushData: {
                            title: "Başlık",
                            message: "Mesaj",
                            image: "Resim",
                            launchUrl: "Hedef Url"
                        },
                        scheduleDate: "Zamanlanmış Tarih",
                        providerStatistics: {
                            successful: "İletilen"
                        }
                    }
                },
                provider: {
                    title: "Bildirim Sağlayıcıları",
                    subTitle: "Bildirim Sağlayıcı Listesi",
                    new: "Yeni Bildirim Sağlayıcı",
                    edit: "Bildirim Sağlayıcı Düzenleme",
                    credential: {
                        title: "Kimlik"
                    },
                    cols: {
                        type: "Tip",
                        code: "Kod",
                        name: "Ad",
                        statistic: {
                            messageablePlayer: "Aktif Kullanıcı"
                        }
                    },
                    responseMessages: {
                        invalid_handler: "Geçersiz sağlayıcı kodu girdiniz."
                    }
                },
            },
            teacherBookRequest: {
                title: "Öğretmen Kitap Talepleri",
                subTitle: "Öğretmen Kitap Talep Listesi",
                detail: "Talep Detayları",
                cols: {
                    firstname: "Ad",
                    lastname: "Soyad",
                    email: "E-Posta",
                    phone: "Telefon",
                    corporationName: "Kurum Adı",
                    corporationAddress: "Kurum Adresi",
                    state: "İl",
                    city: "İlçe",
                    branch: "Şube",
                    publisher: "Yayınevi",
                    file: "Dosya",
                    createdAt: "Talep Tarihi"
                }
            },
            yksLgsHazirlikDestegi: {
                title: "YKS & LGS Hazırlık Desteği",
                subTitle: "YKS & LGS Hazırlık Desteği Listesi",
                detail: "Talep Detayları",
                cols: {
                    name_surname: "Ad & Soyad",
                    requested_training: "İstenilen Eğitim",
                    phone: "Telefon",
                    createdAt: "Talep Tarihi"
                }
            },
            questionSolution: {
                category: {
                    title: "Soru Ç. Kategorileri",
                    subTitle: "Soru Ç. Kategori Listesi",
                    new: "Yeni Kategori",
                    newWithParent: "%s > Yeni Kategori",
                    edit: "%s - Kategori Düzenleme",
                    cols: {
                        title: "Başlık"
                    }
                },
                book: {
                    title: "Soru Ç. Kitapları",
                    titlePattern: "%s - Kitapları",
                    subTitle: "Soru Ç. Kitap Listesi",
                    new: "Yeni Kitap",
                    edit: "Kitap Düzenleme",
                    cols: {
                        title: "Başlık",
                        image: "Resim",
                        chapterCount: "Bölüm Sayısı"
                    },
                    chapter: {
                        title: "Kitap Bölümleri",
                        titlePattern: "%s - Bölümleri",
                        subTitle: "Soru Ç. Kitap Bölüm Listesi",
                        new: "Yeni Bölüm",
                        newWithParent: "%s > Yeni Bölüm",
                        edit: "Bölüm Düzenleme",
                        cols: {
                            title: "Başlık",
                            questionCount: "Soru Sayısı"
                        },
                        question: {
                            title: "Kitap Bölüm Soruları",
                            titlePattern: "%s - Soruları",
                            subTitle: "Soru Ç. Kitap Bölüm Soru Listesi",
                            new: "Yeni Soru",
                            newMulti: "Toplu Yeni Soru",
                            edit: "Soru Düzenleme",
                            questionPatternWithName: "Soru %d (%s)",
                            questionPattern: "Soru %d",
                            cols: {
                                title: "Başlık",
                                media: {
                                    title: "Medya",
                                    dragFile: "Medyayı buraya sürükleyin veya yüklemek için tıklayın"
                                },
                            },
                            warning: {
                                requiredMedia: "Lütfen medya seçin veya sürükleyin.."
                            }
                        }
                    }
                }
            },
            form: {
                title: "Formlar",
                subTitle: "Form Listesi",
                cols: {
                    title: "Başlık",
                    reply_count: "Yanıt Sayısı"
                },
                save: {
                    newForm: "Yeni Form",
                    editForm: "%s - Form Düzenleme",
                    tabs: {
                        general: "Genel",
                        questions: "Sorular",
                        replies: "Yanıtlar"
                    },
                    cols: {
                        description: "Açıklama",
                        image: "Kapak Fotoğraf",
                        answer_after_redirect: "Yanıtlandığında Yönlendir",
                        answer_after_redirect_url: "Yönlendirilecek URL"
                    },
                    seoCols: {
                        slug: "Url",
                        title: "Başlık",
                        description: "Açıklama",
                        keywords: "Anahtar Kelimeler"
                    },
                    informationBoxes: {
                        answer_after_redirect: "Kullanıcı formu cevapladığında farklı bir sayfaya yönlendirmek istiyorsanız \"Evet\" olarak seçin ve aşağıdaki alana URL giriniz. \"Hayır\" olarak bırakıldığında yanıtın kaydedildiğine dair bir mesaj gösterilecektir."
                    }
                },
                element: {
                    title: "Sorular",
                    subTitle: "Soru Listesi",
                    cols: {
                        type: "Tip",
                        title: "Başlık",
                        sort: "Sıra"
                    }
                },
                answer: {
                    title: "Yanıtlar",
                    subTitle: "Yanıt Listesi",
                    detail: "Yanıt Detay",
                    cols: {
                        answer_count: "Yanıtlanan Soru",
                        ip: "IP",
                        userAgent: "User Agent",
                        createdAt: "Tarih"
                    }
                }
            }
        },
        statistic: {
            general: {
                title: "Genel İstatistikler",
                general: {
                    title: "Genel",
                    cols: {
                        product: "Ürün",
                        admin: "Yönetici",
                        user: "Kullanıcı",
                        purchaserUser: "SAK",
                        recordStreamCount: "KVS",
                        recordStreamWatchingHour: "KVİS"
                    },
                    tooltip: {
                        purchaserUser: "Satın Alan Kullanıcı",
                        recordStreamCount: "Kayıt Video Sayısı",
                        recordStreamWatchingHour: "Kayıt Video İzleme Saati"
                    }
                },
                login: {
                    title: "Giriş İstatistiği",
                    serial: {
                        loginCount: "Giriş Sayısı"
                    },
                    tab: {
                        general: "Genel",
                        last: "Son",
                        most: "En Fazla"
                    }
                },
                saleReport: {
                    title: "Satış Raporu",
                    price: "Fiyat",
                    total: "Toplam",
                    serial: {
                        count: "Sayı"
                    }
                },
                saleMinMaxProduct: {
                    title: "Ürün Satış İstatistiği",
                    serial: {
                        saleCount: "Satış Sayısı"
                    },
                    tab: {
                        min: "En Az",
                        max: "En Fazla"
                    }
                },
                exam: {
                    common: {
                        title: "Sınav Genel İstatistik",
                        cols: {
                            total: "Toplam",
                            answered: "Cevaplanma Sayısı"
                        }
                    },
                    user: {
                        title: "Sınav Kullanıcı İstatistiği",
                        averageGrade: "Ortalama",
                        serial: {
                            answeredCount: "Cevap Sayısı",
                        },
                        tab: {
                            mostAnswered: "En fazla Cevaplayan",
                            generalBest: "Genel En İyi"
                        }
                    }
                },
                refresh: "İstatistiği Yenile"
            },
            student: {
                title: "Öğrenci İstatistikleri",
                filter: {
                    title: "Öğrenciler",
                    description: "Lütfen istatistiğini görmek istediğiniz öğrencileri seçiniz.",
                    userSearch: "Ad veya soyad girin.",
                    button: "İstatistikleri Gör"
                },
                totalOrder: {
                    success: {
                        title: "Başarılı Sipariş"
                    },
                    wrong: {
                        title: "Başarısız Sipariş"
                    },
                    cols: {
                        count: "Sayı",
                        price: "Fiyat",
                    }
                },
                lastLogin: {
                    title: "Son Giriş Kayıtları",
                    subTitle: "Son Giriş Kayıt Listesi",
                    cols: {
                        createdAt: "Tarih"
                    }
                },
                totalWatchLesson: {
                    title: "Toplam Ders İzleme",
                    cols: {
                        totalDuration: "Toplam Süre",
                        totalWatchingDuration: "Toplam İzleme Süresi"
                    }
                },
                totalParagraph: {
                    title: "Toplam Paragraf İstastiği",
                    cols: {
                        total: "Toplam",
                        uniqueTotal: "Benzersiz Toplam",
                        totalSpentTime: "THZ"
                    },
                    tooltip: {
                        totalSpentTime: "Toplam Harcanan Zaman"
                    }
                },
                totalExam: {
                    title: "Toplam Sınav",
                    cols: {
                        total: "Toplam",
                        uniqueTotal: "Benzersiz Toplam",
                        averageGrade: "Ortalama Başarı"
                    }
                },
                lastReadParagraph: {
                    title: "Son Okunan Paragraf",
                    cols: {
                        readingTime: "Okuma Süresi",
                        spentTime: "Harcanan Zaman"
                    }
                },
                lastExamResult: {
                    title: "Son Sınav Sonuçları",
                    cols: {
                        grade: "Başarı",
                        startDate: "Başlangıç Tarihi",
                        endDate: "Bitiş Tarihi"
                    }
                },
                lastWatchLesson: {
                    title: "Son İzlenen Ders",
                    cols: {
                        lectureTitle: "Konu Başlığı",
                        firstWatchingStartDate: "İlk İzleme Tarihi",
                        watchingDuration: "İzleme Süresi"
                    },
                },
                examMinMaxGrade: {
                    min: {
                        title: "En Düşük Sınav Başarısı"
                    },
                    max: {
                        title: "En Yüksek Sınav Başarısı"
                    },
                    cols: {
                        title: "Sınav",
                        grade: "Başarı",
                        startDate: "Başlangıç Tarihi",
                        endDate: "Bitiş Tarihi",
                    }
                },
                warning: {
                    requiredUser: "Lütfen öğrenci seçimi yapınız.",
                    notFoundStatistic: {
                        title: "Gösterilecek İstatistik Bulunumadı!",
                        description: "Öğrenciye ait herhangi bir gösterilecek istatistik bulunamadı."
                    }
                },
            },
            lesson: {
                title: "Ders İstatistikleri",
                filter: {
                    title: "Ürünler",
                    description: "Lütfen istatistiğini görmek istediğiniz ürünleri seçiniz.",
                    button: "İstatistikleri Gör"
                },
                userWatchLesson: {
                    title: "%s - Kullanıcı Kayıttan İzlenme",
                    subTitle: "Kayıttan İzlenme İstatistiği",
                    exportTooltip: "Sadece '%s' adlı dersin kayıttan izlenme istatistiği, dışa aktarılır.",
                    cols: {
                        user: "Kullanıcı",
                        statistic: "Toplam/İzlenen",
                        watchPercent: "İzleme Oranı"
                    }
                },
                warning: {
                    requiredProduct: "Lütfen ürün seçimi yapınız.",
                    notFoundStatistic: {
                        title: "Gösterilecek İstatistik Bulunumadı!",
                        description: "Ürüne ait herhangi bir gösterilecek istatistik bulunamadı."
                    }
                },
            },
            product: {
                title: "Ürün İstatistikleri - Genel",
                refresh: "İstatistiği Yenile",
                saleReport: {
                    title: "Ürün Satış İstatistiği",
                    subTitle: "Ürün Satış İstatistik Listesi",
                    exportTooltip: "Tüm ürünlerin sadece satış istatistiği, dışa aktarılır.",
                    cols: {
                        productID: "Ürün ID",
                        product: "Ürün",
                        saleCount: "Satış Sayısı",
                        lastOrderedDate: "Son Satın Alınma Tarihi"
                    }
                },
                specific: {
                    title: "Ürün İstatistikleri - Belirli",
                    filter: {
                        title: "Ürünler",
                        description: "Lütfen istatistiğini görmek istediğiniz ürünleri seçiniz.",
                        button: "İstatistikleri Gör"
                    },
                    warning: {
                        requiredProduct: "Lütfen ürün seçimi yapınız.",
                        notFoundStatistic: {
                            title: "Gösterilecek İstatistik Bulunumadı!",
                            description: "Ürüne ait herhangi bir gösterilecek istatistik bulunamadı."
                        }
                    },
                    common: {
                        saleReport: {
                            title: "Ürün Satış İstatistiği",
                            cols: {
                                product: "Ürün",
                                saleCount: "Satış Sayısı"
                            }
                        }
                    }
                }
            }
        },
        account: {
            overview: {
                title: "Profil Detayları",
                cols: {
                    name: "Ad",
                    surname: "Soyad",
                    email: "E - Posta",
                    phone: "Telefon",
                }
            },
            settings: {
                signInMethod: {
                    title: "Oturum Açma Yöntemi",
                    emailAddress: "E - Posta Adresi",
                    password: {
                        title: "Şifre",
                        cols: {
                            currentPassword: "Şuanki Şifre",
                            password: "Şifre",
                            passwordConfirmation: "Şifre Tekrar",
                        },
                        changePassword: "Şifre Değiştir",
                        updatePassword: "Şifreyi Güncelle",
                        responseMessages: {
                            currentPasswordInvalid: "Şuanki şifre geçersiz."
                        }
                    }
                }
            }
        },
        page: {
            title: "Sayfalar",
            subTitle: "Sayfa Listesi",
            cols: {
                id: "ID",
                title: "Başlık"
            },
            save: {
                newPage: "Yeni Sayfa",
                editPage: "%s - Sayfa Düzenleme",
                seoSettings: "Seo Ayarları",
                pageWidth: {
                    normal: "Normal",
                    large: "Geniş"
                },
                cols: {
                    slug: "Url",
                    content: "İçerik",
                    poster: "Öne Çıkan Resim",
                    pageWidth: "Sayfa Genişliği"
                },
                seoCols: {
                    title: "Başlık",
                    description: "Açıklama",
                    keywords: "Anahtar Kelimeler"
                }
            }
        },
        stream: {
            provider: {
                title: "Medya Sağlayıcıları",
                subTitle: "Medya Sağlayıcı Listesi",
                cols: {
                    id: "ID",
                    code: "Kod",
                    name: "Adı"
                },
                newStreamProvider: "Yeni Sağlayıcı Ekle",
                editStreamProvider: "Sağlayıcı Düzenle",
                account: {
                    title: "Sağlayıcı Hesapları",
                    titlePattern: "%s - Sağlayıcı Hesapları",
                    subTitle: "Sağlayıcı Hesap Listesi",
                    newAccount: "Yeni Hesap Ekle",
                    editAccount: "Hesabı Düzenle",
                    cols: {
                        id: "ID",
                        title: "Başlık"
                    },
                    credential: {
                        title: "Kimlik"
                    }
                }
            }
        },
        protectArea: {
            title: "Erişim Kısıtlı",
            description: "Üzgünüm, bu sayfayı görüntüleyebilmek için yeterli izne sahip değilsin.",
            forbiddenEndpoints: "Gereken Yetkileri Göster"
        }
    },
    filters: {
        title: "Filtre Seçenekleri",
        ecommerce: {
            product: {
                id: "ID",
                title: "Ürün Başlığı",
                type: "Ürün Tipi",
                group: "Ürün Grubu"
            },
            order: {
                id: "ID",
                status: "Durumlar",
                paymentMethod: "Ödeme Yöntemleri",
                shippingMethod: "Kargo Yöntemi",
                shippingTrackingNumber: "Kargo Numarası",
                notShipped: "Kargoya Verilmeyenler",
                installmentCount: "Taksit Sayısı",
                userEmail: "Kullanıcı E - Posta",
                states: "İller",
                admin: "Yönetici",
                onlyUserOrder: "Sadece Kullanıcı Siparişleri",
                containingProduct: "Şu Ürünü İçersin",
                couponCode: "Şu Kuponu İçersin",
                createdAtMin: "Sipariş Tarihi (Min.)",
                createdAtMax: "Sipariş Tarihi (Maks.)",
                changeUsageMulti: {
                    admin: "Yönetici",
                    state: "Durum"
                },
                usageFilter: {
                    title: "Kullanım Durumu",
                    option: {
                        inUse: "Kullanımda Olanlar",
                        ended: "Sona Erenler",
                        notStarted: "Henüz Başlamamışlar"
                    }
                },
                usageDataMin: "Kullanım Tarihi (Min.)",
                usageDataMax: "Kullanım Tarihi (Maks.)",
                shipping: {
                    orderNo: "Sipariş No",
                    user: "Kullanıcı",
                    userEmail: "Kullanıcı E-Posta",
                    shipping: "Kargo",
                    shippingStatus: "Kargolama Durumu"
                }
            },
            user: {
                id: "ID",
                name: "Ad veya Soyad",
                firstname: "Ad",
                lastname: "Soyad",
                email: "E - Posta",
                identificationNumber: "Kimlik Numarası",
                mobilePhone: "Telefon Numarası",
                birthDate: "Doğum Tarihi",
                isAbroad: "Yurt dışında mı?",
                createdAtMin: "Oluşturulma Tarihi (Min.)",
                createdAtMax: "Oluşturulma Tarihi (Maks.)",
                status: "Durum"
            },
            discountCoupon: {
                code: "Kod",
                discountType: "Kupon Tipi",
                startDate: "Başlangıç Tarihi",
                endDate: "Bitiş Tarihi",
                createdAtMin: "Oluşturulma Tarihi (Min.)",
                createdAtMax: "Oluşturulma Tarihi (Maks.)",
                used: "Kullanıldı"
            },
            invoice: {
                userSearch: "Ad veya soyad girin.",
                id: "ID",
                user: "Kullanıcı",
                status: "Durum",
                invoiceType: "Fatura Tipi",
                providerLegalizeStatus: "Resmileştirme Durumu",
                orderID: "Sipariş No",
                sendToProvider: "Sağlayıcıya gönderildi mi ?",
                hasRuntimeError: "Hatalı Olanları Göster",
                paymentMethodApproved: "Ödemesi Onaylananlar",
                provider: {
                    product: {
                        id: "ID",
                        title: "Ürün Adı",
                        matchState: "Eşleşme Durumu"
                    }
                }
            }
        },
        module: {
            branchCourse: {
                reservation: {
                    userSearch: "Ad veya soyad girin.",
                    branchCourse: "Şube Dersleri",
                    user: "Kullanıcı",
                    firstname: "Ad",
                    lastname: "Soyad",
                    email: "E - Posta",
                    mobilePhone: "Telefon",
                    approved: "Onaylandı"
                },
                block: {
                    user: "Kullanıcı",
                    admin: "Engelleyen",
                }
            },
            outQuestion: {
                category: "Kategori",
                title: "Başlık"
            },
            exam: {
                session: {
                    user: {
                        userID: "Kullanıcı ID",
                        userEmail: "Kullanıcı Email",
                        sessionID: "Oturum ID",
                        examID: "Sınav ID",
                        justReservation: "Sadece Rezervasyonlar",
                        justStarted: "Sadece Sınava Başlamışlar",
                        justEnded: "Sadece Sınavı Bitenler"
                    }
                },
                question: {
                    category: "Kategori",
                    type: "Tip",
                    magic: "Soru Adı veya Cevap",
                    gain: "Kazanımlar"
                },
                result: {
                    userEmail: "Kullanıcı Email",
                    userID: "Kullanıcı ID",
                    examID: "Sınav ID",
                    sessionID: "Oturum ID",
                },
                category: "Kategori",
                templateType: "Tasarım Tipi",
                questionType: "Soru Tipi",
                examDate: "Sınav Tarihi",
                examResulted: "Sonuçlandı",
                withoutSession: "Oturumsuz Sınav"
            },
            tracking: {
                name: "Ad",
                location: "Konum"
            },
            teacherBookRequest: {
                firstname: "Ad",
                lastname: "Soyad",
                email: "E-Posta",
                phone: "Telefon",
                corporationName: "Kurum Adı",
                states: "İlçeler",
                createdAtMin: "Talep Tarihi (Min.)",
                createdAtMax: "Talep Tarihi (Maks.)",
            },
            yksLgsHazirlikDestegi: {
                name_surname: "Ad & Soyad",
                phone: "Telefon",
                createdAtMin: "Talep Tarihi (Min.)",
                createdAtMax: "Talep Tarihi (Maks.)"
            },
            questionSolution: {
                book: {
                    title: "Başlık",
                    chapter: {
                        question: {
                            title: "Başlık",
                        }
                    }
                }
            },
            form: {
                answer: {
                    ip: "Ip Adresi",
                    createdAtMin: "Yanıt Tarihi (Min.)",
                    createdAtMax: "Yanıt Tarihi (Maks.)"
                }
            }
        },
        system: {
            admin: {
                id: "ID"
            },
            sms: {
                provider: {
                    code: "Kod",
                    name: "Ad"
                }
            },
            linkManager: {
                type: "Tip",
                slug: "Url"
            },
        },
        page: {
            title: "Başlık",
            slug: "Url"
        },
        statistic: {
            product: {
                productTitle: "Ürün Adı",
                specific: {
                    common: {
                        saleReport: {
                            createdAtMin: "Sipariş Tarihi (Min.)",
                            createdAtMax: "Sipariş Tarihi (Maks.)",
                        }
                    }
                }
            },
            lesson: {
                userName: "Kullanıcı Ad & Soyad",
                userEmail: "Kullanıcı E-Posta"
            }
        }
    },
    customTable: {
        noData: "Listelenecek veri bulunamadı.",
        showingPageInformationPattern: "%s kayıttan, %d ile %d arası gösteriliyor",
    },
    formElement: {
        title: "Form Elemanları",
        titlePattern: "%s - Form Elemenları",
        subTitle: "Form Eleman Listesi",
        new: "Yeni Form Elemanı",
        edit: "Eleman Düzenleme",
        elementAttributeSettings: "Eleman Özellik Ayarları",
        elementAvailableAttributes: "Kullanılabilir Özellikler",
        elementItems: "Eleman Öğeleri",
        cols: {
            type: "Tip",
            name: "Ad",
            title: "Başlık",
            description: "Açıklama",
        },
        inputTypes: {
            text: "Metin",
            email: "E-Posta",
            mobile_phone: "Cep Telefonu",
            password: "Şifre",
            number: "Sayı",
            radio: "Radyo",
            checkbox: "Onay Kutusu",
            file: "Dosya"
        },
        addItem: {
            value: "Değer",
            text: "Metin"
        },
        attributes: {
            class: "Class",
            accept: "Accept",
            required: "Zorunlu",
            placeholder: "Placeholder",
            size: "Boyut",
            min: "Min. Sınır",
            max: "Maks. Sınır",
            rows: "Satır Sayısı",
            cols: "Sütun Sayısı",
            maxlength: "Max. Uzunluk",
            minlength: "Min. Uzunluk",
            step: "Arttırım",
            multiple: "Çoklu",
            enable_other: "Diğer Seçeneği",
            unique: "Benzersiz"
        },
        informationBoxes: {
            elementAvailableAttributes: "Form elemanlarının davranışlarını değiştirmek için aşağıdaki alandan bir seçim yaparak ek özellikler belirtebilirsiniz.",
            attributes: {
                enable_other: "Bu form elemanına tanımladığınız cevaplar haricinde başka bir yanıt alınmasını istiyorsanız bu özelliği etkinleştirebilirsiniz.",
                unique: "Bu özellik, her kullanıcıdan yalnızca bir yanıt alınmasını gerektiren durumlarda benzersiz cevaplar elde etmeye yardımcı olur. Örneğin, bir kullanıcının \"E-Posta\" adresi talep ediliyorsa ve bu adrese ait yalnızca tek bir yanıt isteniyorsa, bu özelliği kullanabilirsiniz."
            }
        },
        warnings: {
            fillElementItems: "Lütfen öğenin değer ve metin alanını tam doldurun.",
            fillElementItemsJustText: "Lütfen eklenecek öğenin metin alanını doldurunuz.",
            elementRequiredItems: "Öğe eklemelisiniz.",
            fillAddedElementItems: "Lütfen daha önce eklediğiniz öğeleri tam doldurun.",
            fillAddedElementRowItems: "Lütfen daha önceden eklediğiniz satır öğelerini kontrol ediniz.",
            fillAddedElementColumnItems: "Lütfen daha önceden eklediğiniz satır öğelerini kontrol ediniz.",
            elementRequiredRowItems: "Lütfen en az 1 adet satır ekleyeniz.",
            elementRequiredColumnItems: "Lütfen en az 1 adet sütun ekleyeniz."
        }
    },
    userMenu: {
        language: "Dil",
        accountSettings: "Hesap Ayarları",
        signOut: "Çıkış Yap"
    },
    footer: {
        copyrightText: "Tüm hakları saklıdır."
    },
    endpointActions:{
        get: "Görüntüleme",
        post: "Ekleme",
        put: "Güncelleme",
        delete: "Silme"
    },
    responseErrors: {
        record_not_found: "Kayıt bulunamadı, silinmiş veya taşınmış olabilir.",
        method_not_found: "Method bulunamadı, silinmiş veya taşınmış olabilir.",
        the_discounted_price_cannot_be_higher_than_the_regular_price: "İndirimli fiyat, normal fiyattan daha büyük olamaz.",
        question_with_no_correct_answer_can_be_added: "Bu sorunun doğru cevabı belirlenmediği için sınava eklenemez!",
        provider_handler_not_found_by_code: "Koda göre sağlayıcı bulunamadı.",
        validate_error: "Doğrulama Hatası",
        delete_meeting_error: "Manuel silinmiş kayıtları silemezsiniz",
    }
};
