import { createStore } from 'vuex'
import { Actions, Mutations } from "@/store/enum/StoreEnums";

import AuthModule from "@/store/module/AuthModule";
import BodyModule from "@/store/module/BodyModule";
import BreadcrumbsModule from "@/store/module/BreadcrumbsModule";
import ConfigModule from "@/store/module/ConfigModule";
import LocalizationLanguage from "@/store/localization/language";
import LocalizationTax from "@/store/localization/tax";
import LocalizationCountry from "@/store/localization/country";
import LocalizationCountryState from "@/store/localization/country/state";
import LocalizationCountryCity from "@/store/localization/country/city";
import SystemAdmin from "@/store/system/admin";
import SystemAdminGroup from "@/store/system/admin/group";
import SystemAdminGroupEndpoint from "@/store/system/admin/group/endpoint";
import SystemVariable from "@/store/system/variable";
import SystemSetting from "@/store/system/setting";
import SystemSettingRegisterElement from "@/store/system/setting/registerelement";
import SystemSettingRegisterElementType from "@/store/system/setting/registerelement/type";
import SystemSiteSetting from "@/store/system/sitesetting";
import EcommerceProductManagementProduct from "@/store/ecommerce/productmanagement/product";
import EcommerceProductManagementProductCategory from "@/store/ecommerce/productmanagement/product/category";
import EcommerceProductManagementProductGroup from "@/store/ecommerce/productmanagement/product/group";
import EcommerceProductManagementProductType from "@/store/ecommerce/productmanagement/product/type";
import EcommerceProductManagementProductPackageItem from "@/store/ecommerce/productmanagement/product/packageItem";
import EcommerceProductManagementProductVariant from "@/store/ecommerce/productmanagement/product/variant";
import EcommerceProductManagementProductAddon from "@/store/ecommerce/productmanagement/product/addon";
import EcommerceProductManagementProductTrailer from "@/store/ecommerce/productmanagement/product/trailer";
import EcommerceProductManagementProductIndexRaw from "@/store/ecommerce/productmanagement/product/indexraw";
import EcommerceProductManagementVariantItem from "@/store/ecommerce/productmanagement/variant/item";
import EcommerceProductManagementVariantGroup from "@/store/ecommerce/productmanagement/variant/group";
import EcommercePaymentMethod from "@/store/ecommerce/paymentmethod";
import EcommercePaymentMethodType from "@/store/ecommerce/paymentmethod/type";
import EcommercePaymentMethodSubMethod from "@/store/ecommerce/paymentmethod/subMethod";
import EcommerceShippingMethod from "@/store/ecommerce/shippingmethod";
import EcommerceShippingMethodRate from "@/store/ecommerce/shippingmethod/rate";
import EcommerceOrder from "@/store/ecommerce/order";
import EcommerceOrderStatus from "@/store/ecommerce/order/status";
import EcommerceOrderNote from "@/store/ecommerce/order/note";
import EcommerceOrderPaymentCollection from "@/store/ecommerce/order/paymentCollection";
import EcommerceOrderItemChangeUsageMulti from "@/store/ecommerce/order/item/changeUsageMulti";
import EcommerceOrderCreator from "@/store/ecommerce/order/creator";
import EcommerceOrderShipping from "@/store/ecommerce/order/shipping";
import EcommerceUser from "@/store/ecommerce/user";
import EcommerceUserGender from "@/store/ecommerce/user/gender";
import EcommerceUserAddress from "@/store/ecommerce/user/address";
import EcommerceUserCreator from "@/store/ecommerce/user/creator";
import EcommerceUserHistoryLogin from "@/store/ecommerce/user/history/login";
import EcommerceDiscountCoupon from "@/store/ecommerce/discountcoupon";
import EcommerceDiscountCouponCreator from "@/store/ecommerce/discountcoupon/creator";
import EcommerceInvoiceManagementInvoice from "@/store/ecommerce/invoicemanagement/invoice";
import EcommerceInvoiceManagementProvider from "@/store/ecommerce/invoicemanagement/provider"
import EcommerceInvoiceManagementProviderPaymentMethod from "@/store/ecommerce/invoicemanagement/provider/paymentMethod"
import EcommerceInvoiceManagementProviderProduct from "@/store/ecommerce/invoicemanagement/provider/product"
import EcommerceInvoiceManagementProviderProductStructure from "@/store/ecommerce/invoicemanagement/provider/product/productStructure"
import Module from "@/store/module";
import ModuleExamDate from "@/store/module/examdate";
import ModuleLessonType from "@/store/module/lessontype";
import ModuleTeacher from "@/store/module/teacher";
import ModuleTeacherBranch from "@/store/module/teacher/branch";
import ModuleTeacherTrailer from "@/store/module/teacher/trailer";
import ModuleLecture from "@/store/module/lecture";
import ModuleLectureStream from "@/store/module/lecture/stream"
import ModuleLectureDirectory from "@/store/module/lecture/directory"
import ModuleDocument from "@/store/module/document"
import ModuleBranchCourse from "@/store/module/branchcourse";
import ModuleBranchCourseBranch from "@/store/module/branchcourse/branch";
import ModuleBranchCourseReservation from "@/store/module/branchcourse/reservation";
import ModuleBranchCourseReservationBlock from "@/store/module/branchcourse/reservation/block";
import ModuleAdobeConnectGroup from "@/store/module/adobeconnect/group";
import ModuleAdobeConnect2Group from "@/store/module/adobeconnect2/group";
import ModuleOutQuestion from "@/store/module/outquestion";
import ModuleOutQuestionCategory from "@/store/module/outquestion/category";
import ModuleBlog from "@/store/module/blog";
import ModuleBlogCategory from "@/store/module/blog/category";
import ModulePopup from "@/store/module/popup";
import ModulePopupButton from "@/store/module/popup/button";
import ModuleSliderGroup from "@/store/module/slider/group";
import ModuleSliderItem from "@/store/module/slider/item";
import ModuleSliderItemButton from "@/store/module/slider/item/button";
import ModuleExam from "@/store/module/exam";
import ModuleExamCategory from "@/store/module/exam/category";
import ModuleExamSession from "@/store/module/exam/session";
import ModuleExamSessionUser from "@/store/module/exam/session/user";
import ModuleExamTemplateType from "@/store/module/exam/template/type";
import ModuleExamQuestion from "@/store/module/exam/question";
import ModuleExamQuestionCategory from "@/store/module/exam/question/category";
import ModuleExamQuestionType from "@/store/module/exam/question/type";
import ModuleExamQuestionAnswer from "@/store/module/exam/question/answer";
import ModuleExamGain from "@/store/module/exam/gain";
import ModuleExamResult from "@/store/module/exam/result";
import ModuleVoiceRecording from "@/store/module/voicerecord";
import ModuleVoiceRecordingCategory from "@/store/module/voicerecord/category";
import ModuleParagraph from "@/store/module/paragraph";
import ModuleParagraphSentence from "@/store/module/paragraph/sentence";
import ModuleParagraphLevel from "@/store/module/paragraph/level";
import ModuleParagraphQuestion from "@/store/module/paragraph/question";
import ModuleParagraphQuestionType from "@/store/module/paragraph/question/type";
import ModuleParagraphQuestionAnswer from "@/store/module/paragraph/question/answer";
import ModuleZoomHost from "@/store/module/zoom/host"
import ModuleZoomMeeting from "@/store/module/zoom/meeting"
import ModuleZoomMeetingType from "@/store/module/zoom/meeting/type"
import ModuleZoomMeetingRegistrant from "@/store/module/zoom/meeting/registrant"
import ModuleTracking from "@/store/module/tracking";
import ModuleLessonCalendar from "@/store/module/lessoncalendar";
import ModuleSource from "@/store/module/source";
import ModuleSourceCategory from "@/store/module/source/category";
import ModuleSourceGroup from "@/store/module/source/group";
import ModuleProductFeatured from "@/store/module/featuredproduct";
import ModuleProductFeaturedGroup from "@/store/module/featuredproduct/group";
import ModulePushNotification from "@/store/module/push/notification";
import ModulePushStatus from "@/store/module/push/status";
import ModulePushProvider from "@/store/module/push/provider";
import ModulePushProviderType from "@/store/module/push/provider/type";
import ModuleTeacherBookRequest from "@/store/module/teacherbookrequest";
import ModuleYksLgsHazirlikDestegi from "@/store/module/ykslgshazirlikdestegi";
import ModuleQuestionSolutionCategory from "@/store/module/questionsolution/category";
import ModuleQuestionSolutionBook from "@/store/module/questionsolution/book";
import ModuleQuestionSolutionBookChapter from "@/store/module/questionsolution/book/chapter";
import ModuleQuestionSolutionBookChapterQuestion from "@/store/module/questionsolution/book/chapter/question";
import ModuleForm from "@/store/module/form";
import ModuleFormElement from "@/store/module/form/element";
import ModuleFormElementType from "@/store/module/form/element/type";
import ModuleFormAnswer from "@/store/module/form/answer";
import File from "@/store/file";
import Page from "@/store/page";
import LinkManager from "@/store/linkmanager";
import LinkType from "@/store/linkmanager/linktype";
import StatisticGeneral from "@/store/statistic/general";
import StatisticProduct from "@/store/statistic/product";
import SmsProvider from "@/store/sms/provider";
import SmsMultiSend from "@/store/sms/multisend";
import NotificationItem from "@/store/notification/item";
import NotificationItemGroup from "@/store/notification/item/group";
import NotificationItemSubGroup from "@/store/notification/item/subgroup";
import NotificationItemTemplate from "@/store/notification/item/template";
import StreamProvider from "@/store/stream/provider";
import StreamProviderAccount from "@/store/stream/provider/account";

export const store = createStore({
    state: () => ({
        access: true,
        forbiddenEndpoints: []
    }),
    getters: {
        isAccess(state) {
            return state.access;
        },
        forbiddenEndpoints(state) {
            return state.forbiddenEndpoints;
        }
    },
    actions: {
        [Actions.ACCESS](context, access) {
            context.commit(Mutations.SET_ACCESS, access);
        },
        [Actions.FORBIDDEN_ENDPOINT](context, access) {
            context.commit(Mutations.SET_FORBIDDEN_ENDPOINT, access);
        }
    },
    mutations: {
        [Mutations.SET_ACCESS](state, access) {
            state.access = access;
        },
        [Mutations.SET_FORBIDDEN_ENDPOINT](state, endpoint) {
            state.forbiddenEndpoints.push(endpoint);
        },
        [Mutations.RESET_FORBIDDEN_ENDPOINT](state) {
            state.forbiddenEndpoints = [];
        },
    },
    modules: {
        AuthModule,
        BodyModule,
        BreadcrumbsModule,
        ConfigModule,
        localization: {
            namespaced: true,
            modules: {
                language: LocalizationLanguage,
                tax: LocalizationTax,
                country: Object.assign(LocalizationCountry,{
                    modules: {
                        state: LocalizationCountryState,
                        city: LocalizationCountryCity,
                    }
                })
            }
        },
        file: File,
        system: {
            namespaced: true,
            modules: {
                admin: Object.assign(SystemAdmin,{
                    modules: {
                        group: Object.assign(SystemAdminGroup,{
                            modules: {
                                endpoint: SystemAdminGroupEndpoint,
                            }
                        }),
                    }
                }),
                variable: SystemVariable,
                setting: Object.assign(SystemSetting,{
                    modules: {
                        registerElement: Object.assign(SystemSettingRegisterElement,{
                            modules: {
                                type: SystemSettingRegisterElementType
                            }
                        }),
                    }
                }),
                siteSetting: SystemSiteSetting,
            }
        },
        ecommerce: {
            namespaced: true,
            modules: {
                productManagement: {
                    namespaced: true,
                    modules: {
                        product: Object.assign(EcommerceProductManagementProduct,{
                            modules: {
                                category: EcommerceProductManagementProductCategory,
                                packageItem: EcommerceProductManagementProductPackageItem,
                                addon: EcommerceProductManagementProductAddon,
                                trailer: EcommerceProductManagementProductTrailer,
                                group: EcommerceProductManagementProductGroup,
                                type: EcommerceProductManagementProductType,
                                variant: EcommerceProductManagementProductVariant,
                                indexRaw: EcommerceProductManagementProductIndexRaw
                            }
                        }),
                        variant: {
                            namespaced: true,
                            modules: {
                                group: EcommerceProductManagementVariantGroup,
                                item: EcommerceProductManagementVariantItem
                            }
                        }
                    }
                },
                paymentMethod: Object.assign(EcommercePaymentMethod,{
                    modules: {
                        type: EcommercePaymentMethodType,
                        subMethod: EcommercePaymentMethodSubMethod
                    }
                }),
                shippingMethod: Object.assign(EcommerceShippingMethod,{
                    modules: {
                        rate: EcommerceShippingMethodRate
                    }
                }),
                order: Object.assign(EcommerceOrder,{
                    modules: {
                        status: EcommerceOrderStatus,
                        note: EcommerceOrderNote,
                        paymentCollection: EcommerceOrderPaymentCollection,
                        item: {
                            namespaced: true,
                            modules: {
                                changeUsageMulti: EcommerceOrderItemChangeUsageMulti
                            }
                        },
                        creator: EcommerceOrderCreator,
                        shipping: EcommerceOrderShipping
                    }
                }),
                user: Object.assign(EcommerceUser,{
                    modules: {
                        address: EcommerceUserAddress,
                        gender: EcommerceUserGender,
                        creator: EcommerceUserCreator,
                        history: {
                            namespaced: true,
                            modules: {
                                login: EcommerceUserHistoryLogin
                            }
                        }
                    }
                }),
                discountCoupon: Object.assign(EcommerceDiscountCoupon,{
                    modules: {
                        creator: EcommerceDiscountCouponCreator
                    }
                }),
                invoiceManagement: {
                    namespaced: true,
                    modules: {
                        invoice: EcommerceInvoiceManagementInvoice,
                        provider: Object.assign(EcommerceInvoiceManagementProvider,{
                            modules: {
                                paymentMethod: EcommerceInvoiceManagementProviderPaymentMethod,
                                product: Object.assign(EcommerceInvoiceManagementProviderProduct,{
                                    modules: {
                                        productStructure: EcommerceInvoiceManagementProviderProductStructure
                                    }
                                })
                            }
                        }),
                    }
                }
            }
        },
        module: Object.assign(Module,{
            modules: {
                examDate: ModuleExamDate,
                lessonType: ModuleLessonType,
                teacher: Object.assign(ModuleTeacher,{
                    modules: {
                        branch: ModuleTeacherBranch,
                        trailer: ModuleTeacherTrailer,
                    }
                }),
                lecture: Object.assign(ModuleLecture,{
                    modules: {
                        stream: ModuleLectureStream,
                        directory: ModuleLectureDirectory,
                    }
                }),
                document: ModuleDocument,
                branchCourse: Object.assign(ModuleBranchCourse,{
                    modules: {
                        branch: ModuleBranchCourseBranch,
                        reservation: Object.assign(ModuleBranchCourseReservation,{
                            modules: {
                                block: ModuleBranchCourseReservationBlock,
                            }
                        })
                    }
                }),
                adobeConnect: {
                    namespaced: true,
                    modules: {
                        group: ModuleAdobeConnectGroup
                    }
                },
                adobeConnect2: {
                    namespaced: true,
                    modules: {
                        group: ModuleAdobeConnect2Group
                    }
                },
                outQuestion: Object.assign(ModuleOutQuestion,{
                    modules: {
                        category: ModuleOutQuestionCategory
                    }
                }),
                blog: Object.assign(ModuleBlog,{
                    modules: {
                        category: ModuleBlogCategory
                    }
                }),
                popup: Object.assign(ModulePopup,{
                    modules: {
                        button: ModulePopupButton
                    }
                }),
                slider: {
                    namespaced: true,
                    modules: {
                        group: ModuleSliderGroup,
                        item: Object.assign(ModuleSliderItem,{
                            modules: {
                                button: ModuleSliderItemButton
                            }
                        }),
                    }
                },
                exam: Object.assign(ModuleExam,{
                    modules: {
                        category: ModuleExamCategory,
                        session: Object.assign(ModuleExamSession,{
                            modules: {
                                user: ModuleExamSessionUser
                            }
                        }),
                        template: {
                            namespaced: true,
                            modules: {
                                type: ModuleExamTemplateType
                            }
                        },
                        question: Object.assign(ModuleExamQuestion,{
                            modules: {
                                category: ModuleExamQuestionCategory,
                                type: ModuleExamQuestionType,
                                answer: ModuleExamQuestionAnswer
                            }
                        }),
                        gain: ModuleExamGain,
                        result: ModuleExamResult
                    }
                }),
                voiceRecording: Object.assign(ModuleVoiceRecording,{
                    modules: {
                        category: ModuleVoiceRecordingCategory
                    }
                }),
                paragraph: Object.assign(ModuleParagraph,{
                    modules: {
                        sentence: ModuleParagraphSentence,
                        level: ModuleParagraphLevel,
                        question: Object.assign(ModuleParagraphQuestion,{
                            modules: {
                                type: ModuleParagraphQuestionType,
                                answer: ModuleParagraphQuestionAnswer
                            }
                        }),
                    }
                }),
                zoom: {
                    namespaced: true,
                    modules: {
                        host: ModuleZoomHost,
                        meeting: Object.assign(ModuleZoomMeeting,{
                            modules: {
                                type: ModuleZoomMeetingType,
                                registrant: ModuleZoomMeetingRegistrant
                            }
                        }),
                    }
                },
                tracking: ModuleTracking,
                lessonCalendar: ModuleLessonCalendar,
                source: Object.assign(ModuleSource,{
                    modules: {
                        group: ModuleSourceGroup,
                        category: ModuleSourceCategory,
                    }
                }),
                featuredProduct: Object.assign(ModuleProductFeatured,{
                    modules: {
                        group: ModuleProductFeaturedGroup
                    }
                }),
                push: {
                    namespaced: true,
                    modules: {
                        notification: ModulePushNotification,
                        status: ModulePushStatus,
                        provider: Object.assign(ModulePushProvider,{
                            modules: {
                                type: ModulePushProviderType
                            }
                        }),
                    }
                },
                teacherBookRequest: ModuleTeacherBookRequest,
                yksLgsHazirlikDestegi: ModuleYksLgsHazirlikDestegi,
                form: Object.assign(ModuleForm,{
                    modules: {
                        element: Object.assign(ModuleFormElement, {
                            modules: {
                                type: ModuleFormElementType
                            }
                        }),
                        answer: ModuleFormAnswer
                    }
                }),
                questionSolution: {
                    namespaced: true,
                    modules: {
                        category: ModuleQuestionSolutionCategory,
                        book: Object.assign(ModuleQuestionSolutionBook, {
                            modules: {
                                chapter: Object.assign(ModuleQuestionSolutionBookChapter, {
                                    modules: {
                                        question: ModuleQuestionSolutionBookChapterQuestion
                                    }
                                })
                            }
                        }),
                    }
                }
            }
        }),
        linkManager: Object.assign(LinkManager,{
            modules: {
                linkType: LinkType
            }
        }),
        page: Page,
        statistic: {
            namespaced: true,
            modules: {
                general: StatisticGeneral,
                product: StatisticProduct
            }
        },
        sms: {
            namespaced: true,
            modules: {
                provider: SmsProvider,
                multiSend: SmsMultiSend
            }
        },
        notification: {
            namespaced: true,
            modules: {
                item: Object.assign(NotificationItem,{
                    modules: {
                        group: NotificationItemGroup,
                        subGroup: NotificationItemSubGroup,
                        template: NotificationItemTemplate
                    }
                }),
            }
        },
        stream: {
            namespaced: true,
            modules: {
                provider: Object.assign(StreamProvider,{
                    modules: {
                        account: StreamProviderAccount
                    }
                })
            }
        }
    }
});


export default store;